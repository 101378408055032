import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch } from 'react-redux';

function NavbarMobileToggleButton(props) {
  const dispatch = useDispatch();

  return (
    <IconButton
      className={props.className}
      onClick={() => dispatch(Actions.navbarToggleMobile())}
      color='inherit'
      disableRipple
    >
      {props.children}
    </IconButton>
  );
}

NavbarMobileToggleButton.defaultProps = {
  children: <MenuIcon />
};

export default NavbarMobileToggleButton;
