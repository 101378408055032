import { useEffect, useState } from 'react';

export default function useResize(widthThreshold) {
  const [widthThresholdReached, setWidthThresholdReached] = useState(false);

  function isThresholdReached() {
    setWidthThresholdReached(window.innerWidth > widthThreshold);
  }

  useEffect(() => {
    isThresholdReached();

    window.addEventListener('resize', isThresholdReached);
    return () => {
      window.removeEventListener('resize', isThresholdReached);
    };
  }, []);

  return widthThresholdReached;
}
