import PhotoIcon from '@material-ui/icons/Photo';
import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import React from 'react';
import { useDispatch } from 'react-redux';

import ActionIcon from './ActionIcon';

function ScreenShootActionIcon({ serial, deviceType }) {
  const dispatch = useDispatch();

  const handleScreenshot = ev => {
    ev.stopPropagation();
    dispatch(Actions.getScreenshot(serial));
    dispatch(Actions.clearLatestAvailableScreenshot());
    dispatch(Actions.setSelectedDeviceSerial(serial));
    dispatch(Actions.setSelectedDeviceType(deviceType));
    dispatch(Actions.setScreenshotImageLoadingTrue());
    dispatch(Actions.getLatestAvailableScreenshot(serial, Math.floor(Date.now() / 1000)));
    dispatch(Actions.openScreenshotDialog());
  };

  return <ActionIcon title='Screenshot' icon={<PhotoIcon />} onClick={handleScreenshot} />;
}

export default ScreenShootActionIcon;
