import { TextField } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import TranslationTooltip from './TranslationTooltip';

function EnhancedTextField(props) {
  const translatorModeEnabled = useSelector(({ common }) => common.common.translatorModeEnabled);

  if (translatorModeEnabled)
    return (
      <TranslationTooltip title={props.label}>
        <TextField {...props} label={<Translate value={props.label} />} />
      </TranslationTooltip>
    );
  else return <TextField {...props} label={props.label ? <Translate value={props.label} /> : null} />;
}

export default EnhancedTextField;
