import { Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from '../../../@fuse/hooks';
import fullCwatchLogo from '../../../assets/fullCwatchLogo.jpg';
import {
  fetchInvitationLinkDetails,
  fetchIsAccountCompleted,
  setSelectedView,
  updateInvitations
} from '../../auth/store/LoginSlice';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import Invitations from '../../fuse-layouts/shared-components/Invitations';
import GoBackToLoginButton from '../GoBackToLoginButton';
import LoginFooter from '../LoginFooter';
import { VIEW_COMPLETE_REGISTRATION_NEW_USER } from '../constants';

const defaultFormState = {
  accepted: [],
  declined: []
};

export default function InvitationView() {
  const dispatch = useDispatch();

  const {
    invitationLinkDetailsError,
    invitationLinkDetailsLoading,
    token,
    isAccountCompleted,
    isAccountCompletedLoading,
    invitationLinkDetails
  } = useSelector(state => state.login);

  const { form, setInForm } = useForm(defaultFormState);

  useEffect(() => {
    dispatch(fetchIsAccountCompleted(token));
  }, []);

  useEffect(() => {
    if (isAccountCompleted !== null) {
      if (isAccountCompleted) dispatch(fetchInvitationLinkDetails(token));
      else dispatch(setSelectedView(VIEW_COMPLETE_REGISTRATION_NEW_USER));
    }
  }, [isAccountCompleted]);

  function isLoading() {
    return invitationLinkDetailsLoading || isAccountCompletedLoading;
  }

  function submit() {
    dispatch(updateInvitations(token, form.accepted, form.declined));
  }

  return (
    <div className='flex h-full flex-col p-32'>
      <GoBackToLoginButton />
      <img className='m-32 w-256' src={fullCwatchLogo} alt='logo' />

      {isLoading() ? (
        <div className='mt-32 flex justify-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          {invitationLinkDetailsError !== null ? (
            <Alert severity='error'>
              <EnhancedTranslate value={invitationLinkDetailsError} />
            </Alert>
          ) : (
            <>
              <div className='flex h-full flex-col overflow-hidden'>
                <Invitations
                  invitations={invitationLinkDetails?.invitations}
                  onChangeAccepted={newAccepted => {
                    setInForm('accepted', newAccepted);
                  }}
                  onChangeDeclined={newDeclined => {
                    setInForm('declined', newDeclined);
                  }}
                />
              </div>
              <Button
                variant='contained'
                color='secondary'
                disabled={form.accepted.length === 0 && form.declined.length === 0}
                onClick={submit}
                className='mx-auto mt-16 w-full'
              >
                <EnhancedTranslate value='Confirm' />
              </Button>
            </>
          )}
        </>
      )}
      <div className='mt-8 flex-1' />
      <LoginFooter />
    </div>
  );
}
