// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'app/App';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-muli';

import { isEnvDev } from './lib/commonFunctions';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';

if (!isEnvDev()) {
  Sentry.init({
    dsn: 'https://2922e6f85b434a02bf4d1e6d504b9c96@o1346620.ingest.sentry.io/6624686',
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE_NUMBER,
    environment: process.env.REACT_APP_INSTANCE_TYPE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
