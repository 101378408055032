import { useForm } from '@fuse/hooks';
import { Button, DialogActions } from '@material-ui/core';
import EnhancedTextField from 'app/common-components/EnhancedTextField';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import GooglePlacesAutocomplete from 'app/common-components/GooglePlacesAutocomplete';
import PhoneInputWithFlagsAndCountryCodes from 'app/common-components/PhoneInputWithFlagsAndCountryCodes';
import { showMessage } from 'app/store/actions';
import axios from 'axios';
import { getCountry } from 'lib/languages';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import validator from 'validator';

const DEFAULT_STATE = {
  fullName: '',
  phone: '',
  email: '',
  facilityName: ''
};

function RecommendForm({ onClose }) {
  const dispatch = useDispatch();

  const { form, setInForm } = useForm(DEFAULT_STATE);
  const obid = useSelector(({ common }) => common.common.obid);
  const locale = useSelector(({ i18n }) => i18n.locale);

  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const [error, setError] = useState({
    fullName: false,
    email: false,
    phone: false,
    facilityName: false
  });

  const getDividedFullName = () => {
    const [name, ...surnameParts] = form.fullName.split(' ');
    return {
      name,
      surname: surnameParts.filter(part => part).join(' ')
    };
  };

  const validateFullName = () => {
    const { fullName } = form;
    if (!fullName.trim()) return false;

    const nameParts = fullName
      .trim()
      .split(' ')
      .filter(part => part);

    return nameParts.length >= 2;
  };

  const validateForm = () => {
    const validatedInputs = {
      fullName: !validateFullName(),
      email: !validator.isEmail(form.email),
      phone: form.phone.length < 5,
      facilityName: !form.facilityName.trim()
    };

    setError(validatedInputs);

    return !Object.values(validatedInputs).some(error => error);
  };

  const onSubmit = async () => {
    if (!validateForm()) return;

    try {
      setIsPending(true);
      const { phone, email, facilityName } = form;
      const { name, surname } = getDividedFullName();

      await axios.post(`api/facilities/${obid}/invite-facility`, {
        facilityName,
        name,
        surname,
        email,
        phone
      });

      onClose();
      setIsSubmitError(false);
      dispatch(
        showMessage({
          message: I18n.t('recommendation sent successfully'),
          variant: 'success'
        })
      );
    } catch (error) {
      setIsSubmitError(true);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className='flex flex-col min-h-640 justify-between'>
      <div className='mb-20 text-center'>
        <EnhancedTranslate value='are you satisfied with cwatch' />
      </div>
      <form className='flex-1 flex flex-col justify-between '>
        <div className='mb-36'>
          <div className='mt-40'>
            <div className='mb-16'>
              <EnhancedTranslate value='find a facility you want to recommend to cWatch' />
            </div>
            <GooglePlacesAutocomplete
              onChange={value => {
                if (value && value.structured_formatting.main_text) {
                  setInForm('facilityName', value.structured_formatting.main_text);
                }
              }}
              types={['establishment']}
              label={I18n.t('Facility name')}
              isError={error.facilityName}
              helperText={error.facilityName && I18n.t('location of the facility is required')}
            />
          </div>
          <div className='mt-40'>
            <div className='mb-16'>
              <EnhancedTranslate value='contact data' />
            </div>
            <EnhancedTextField
              fullWidth
              variant='outlined'
              value={form.fullName}
              label='First name and surname'
              onChange={event => {
                setInForm('fullName', event.target.value);
              }}
              error={error.fullName}
              helperText={error.fullName && I18n.t('name and surname are required')}
            />

            <PhoneInputWithFlagsAndCountryCodes
              className='mt-20'
              specialLabel={<EnhancedTranslate value='Phone' />}
              country={getCountry(locale)}
              value={form.phone}
              onChange={(_value, _country, _e, formattedValue) => {
                setInForm('phone', formattedValue);
              }}
              error={error.phone}
              helperText='the phone number must be valid'
            />

            <EnhancedTextField
              fullWidth
              variant='outlined'
              className='mt-20'
              value={form.email}
              label='Email'
              type='email'
              onChange={event => {
                setInForm('email', event.target.value);
              }}
              error={error.email}
              helperText={error.email && I18n.t('Invalid email')}
            />
          </div>
        </div>
        {isSubmitError && (
          <div className='flex justify-end text-red my-20'>
            <EnhancedTranslate value='Something went wrong try again' />
          </div>
        )}
        <DialogActions className='justify-end gap-8'>
          <Button variant='outlined' color='secondary' disabled={isPending} onClick={onClose}>
            <EnhancedTranslate value='Close' />
          </Button>
          <Button
            variant='contained'
            color='secondary'
            disabled={isPending}
            type='submit'
            onClick={event => {
              event.preventDefault();
              onSubmit();
            }}
          >
            <EnhancedTranslate value='Send' />
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}
export default RecommendForm;
