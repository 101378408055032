import { RESET_STORE } from '../../actions/reduxActions';

const initialState = {};

const routes = function (state = initialState, action) {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default routes;
