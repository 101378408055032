import LoginPage from './LoginApp';

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  routes: [
    {
      path: '/login',
      component: LoginPage
    },
    {
      path: '/forgotPassword',
      component: LoginPage
    },
    {
      path: '/newPassword',
      component: LoginPage
    },
    {
      path: '/invitation',
      component: LoginPage
    }
  ]
};
