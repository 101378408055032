import { Badge, makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';

import ActionIcon from './ActionIcon';

const useStyles = makeStyles({
  settingsIcon: {
    transform: 'translate(10px, -12px)',
    scale: '68%',
    position: 'absolute'
  }
});

function SettingsActionIcon({ onClick, isDisabled, isConfigured }) {
  const classes = useStyles();

  const handleOpenSettings = ev => {
    ev.stopPropagation();
    onClick();
  };

  return (
    <ActionIcon
      title='Settings'
      icon={
        <>
          {!isConfigured && <Badge badgeContent='!' color='error' className={classes.settingsIcon} />}
          <SettingsIcon />
        </>
      }
      isDisabled={isDisabled}
      onClick={handleOpenSettings}
    />
  );
}

export default SettingsActionIcon;
