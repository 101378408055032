import React from 'react';

export const DevicesAssignmentHistoryAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/devicesAssignmentHistory',
      component: React.lazy(() => import('app/main/apps/devicesAssignmentHistory/DevicesAssignmentHistoryApp'))
    }
  ]
};
