import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import HelpIcon from 'app/common-components/Icons/HelpIcon';
import React from 'react';

import JobTitlesNestedMenuList from '../JobTitlesNestedMenuList';

const useStyles = makeStyles({
  panel: {
    width: '100%',
    flex: 1
  },
  panelHeader: {
    border: '1px solid #BDBDBD',
    borderRadius: '4px 4px 0px 0px',
    padding: 16
  },
  panelBody: {
    border: '1px solid #BDBDBD',
    borderRadius: '0px 0px 4px 4px',
    borderTop: 0,
    minHeight: 403,
    height: '100%'
  }
});

function AssigneesPanel({
  title,
  tooltip,
  selectedJobTitles,
  selectedUsers,
  disabledJobTitles,
  disabledUsers,
  onJobTitleCheck,
  onUserCheck,
  updatedDisabledUserIds
}) {
  const classes = useStyles();

  return (
    <div className={classes.panel}>
      <div className={classes.panelHeader}>
        <Tooltip title={<EnhancedTranslate value={tooltip} />}>
          <Typography className='typography-body2'>
            <EnhancedTranslate value={title} />
            <HelpIcon />
          </Typography>
        </Tooltip>
      </div>
      <div className={classes.panelBody}>
        <JobTitlesNestedMenuList
          selectedJobTitles={selectedJobTitles}
          selectedUsers={selectedUsers}
          disabledJobTitles={disabledJobTitles}
          disabledUsers={disabledUsers}
          onJobTitleCheck={onJobTitleCheck}
          onUserCheck={onUserCheck}
          updatedDisabledUserIds={updatedDisabledUserIds}
        />
      </div>
    </div>
  );
}

export default AssigneesPanel;
