import React, { useEffect } from 'react';

import { COLOR_ERROR_RED_100, COLOR_SUCCESS_GREEN_100 } from '../../../lib/colors';
import {
  containsLowercase,
  containsNumber,
  containsSpecialCharacter,
  containsUppercase
} from '../../../lib/commonFunctions';
import { MAX_PASSWORD_LENGTH } from '../../../lib/constants';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';

export default function PasswordsValidationRules({ password, passwordConfirmation, setArePasswordsValid }) {
  const rules = [
    {
      validate: (password, passwordConfirmation) => password === passwordConfirmation,
      label: 'password_validation_error_same'
    },
    {
      validate: password => password.length >= 9,
      label: 'password_validation_error_min_length_9'
    },
    {
      validate: password => password.length <= MAX_PASSWORD_LENGTH,
      label: 'password_validation_error_max_length_63'
    },
    {
      validate: password => containsLowercase(password),
      label: 'password_validation_error_lowercase_character'
    },
    {
      validate: password => containsUppercase(password),
      label: 'password_validation_error_uppercase_character'
    },
    {
      validate: password => containsNumber(password),
      label: 'password_validation_error_number_character'
    },
    {
      validate: password => containsSpecialCharacter(password),
      label: 'password_validation_error_special_character'
    }
  ];

  useEffect(() => {
    setArePasswordsValid(rules.every(validation => validation.validate(password, passwordConfirmation)));
  }, [password, passwordConfirmation]);

  return (
    <>
      {rules.map((rule, index) => (
        <p
          key={index}
          style={{
            color: rule.validate(password, passwordConfirmation) ? COLOR_SUCCESS_GREEN_100 : COLOR_ERROR_RED_100
          }}
        >
          <EnhancedTranslate value={rule.label} />
        </p>
      ))}
    </>
  );
}
