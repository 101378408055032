import React, { useEffect, useMemo, useRef } from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';

export const Map = withGoogleMap(({ children, positions }) => {
  const mapRef = useRef(null);
  const fitBoundsToMakeAllMarkersVisible = () => {
    const bounds = new window.google.maps.LatLngBounds();
    positions.map(position => {
      bounds.extend(position);
      return true;
    });
    mapRef.current.fitBounds(bounds);
  };

  useEffect(() => {
    if (positions.length > 1) fitBoundsToMakeAllMarkersVisible();
  }, []);

  function getZoom() {
    if (positions.length === 1) return 14; // marker is visible and some space around (~1km)
    else if (positions.length === 0) return 4; //whole world visible
    else return null; //calculated automatically in fitBoundsToMakeAllMarkersVisible
  }

  function getCenter() {
    if (positions.length === 1) return new window.google.maps.LatLng(positions[0].lat, positions[0].lng);
    else return new window.google.maps.LatLng(0, 0);
  }

  const center = useMemo(() => getCenter(), [positions]);
  const zoom = useMemo(() => getZoom(), [positions]);

  return (
    <GoogleMap center={center} zoom={zoom} ref={mapRef}>
      {children}
    </GoogleMap>
  );
});
