import React from 'react';

function HelpIcon() {
  return (
    <img
      style={{
        width: '1.3em',
        height: '1.3em',
        display: 'inline'
      }}
      alt='img'
      src={`${process.env.PUBLIC_URL}/assets/icons/helpIcon.svg`}
    />
  );
}

export default HelpIcon;
