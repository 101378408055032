import React from 'react';
import { useSelector } from 'react-redux';

import ApiDownPage from '../main/pages/errors/ApiDownPage';

export default function ApiStatusWrapper({ children }) {
  const isApiDown = useSelector(({ common }) => common.common.isApiDown);

  if (isApiDown) return <ApiDownPage />;

  return <>{children}</>;
}
