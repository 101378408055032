import { InputBase } from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { KEYCODE_ARROW_LEFT, KEYCODE_ARROW_RIGHT, KEYCODE_BACKSPACE, KEYCODE_DELETE } from '../../../../lib/constants';

export default function InlineInput({
  value,
  idx,
  handleChange,
  isPreviousElementChip,
  isNextElementChip,
  id,
  isCustom,
  isLastInput
}) {
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [value]);

  const className = `my-input-base-${id}`;

  function getNextInput() {
    const inputs = document.querySelectorAll(`[id^=${className}]`);
    for (let i = 0; i < inputs.length; i++) {
      let inputIdx = parseInt(inputs[i].id.replace(className, ''));
      if (inputIdx > idx) {
        return inputs[i];
      }
    }
    return null;
  }

  function getPreviousInput() {
    const inputs = document.querySelectorAll(`[id^=${className}]`);
    for (let i = inputs.length - 1; i >= 0; i--) {
      let inputIdx = parseInt(inputs[i].id.replace(className, ''));
      if (inputIdx < idx) {
        return inputs[i];
      }
    }
    return null;
  }

  function isArrowKeyPressed(keyCode) {
    return keyCode === KEYCODE_ARROW_LEFT || keyCode === KEYCODE_ARROW_RIGHT;
  }

  return (
    <Fragment>
      <span style={{ position: 'absolute', opacity: 0, zIndex: -100, whiteSpace: 'pre' }} ref={span}>
        {value}
      </span>
      <InputBase
        value={value}
        onChange={e => handleChange(idx, e.target.value)}
        style={{
          display: 'inline',
          color: isCustom ? 'black' : 'rgba(0,0,0,0.37)'
        }}
        inputProps={{
          id: `${className}${idx}`,
          style: {
            display: 'inline-block',
            minWidth: width,
            width: isLastInput ? '100%' : width
          }
        }}
        onKeyDown={e => {
          const keyCode = e.keyCode;
          if (keyCode === KEYCODE_BACKSPACE && isPreviousElementChip(idx)) {
            return;
          }
          if (keyCode === KEYCODE_DELETE && isNextElementChip(idx)) {
            return;
          }
          if (isArrowKeyPressed(keyCode)) {
            if (e.target.selectionStart !== e.target.selectionEnd) return;
            if (keyCode === KEYCODE_ARROW_RIGHT && e.target.selectionStart === value.length) {
              const nextInput = getNextInput();
              if (nextInput) {
                nextInput.focus();
                nextInput.setSelectionRange(0, 0);
              }
            }
            if (keyCode === KEYCODE_ARROW_LEFT && e.target.selectionStart === 0) {
              const previousInput = getPreviousInput();
              if (previousInput) {
                previousInput.focus();
                previousInput.setSelectionRange(previousInput.value.length, previousInput.value.length);
              }
            }
          }
        }}
      />
    </Fragment>
  );
}
