import { AppBar, Dialog, DialogContent, Toolbar, Typography } from '@material-ui/core';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import GoogleScriptsWrapper from 'app/common-components/GoogleScriptsWrapper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RecommendForm from './RecommendForm';
import { closeRecommendDialog } from './store/recommendDialogSlice';

function RecommendDialog() {
  const dispatch = useDispatch();

  const isOpen = useSelector(({ recommendDialog }) => recommendDialog.isOpen);

  const onClose = () => {
    dispatch(closeRecommendDialog());
  };

  return (
    <GoogleScriptsWrapper>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <AppBar position='static' elevation={1}>
          <Toolbar className='flex w-full'>
            <Typography variant='subtitle1' color='inherit'>
              <EnhancedTranslate value='recommend cwatch' />
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className='p-24'>
          <RecommendForm onClose={onClose} />
        </DialogContent>
      </Dialog>
    </GoogleScriptsWrapper>
  );
}
export default RecommendDialog;
