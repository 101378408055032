import axios from 'axios';

import { getCurrentSelectedObid } from '../../../../../../lib/getObid';

export const SET_SETTING_VALUE = '[SETTINGS] SET SETTINGS VALUE';
export const OPEN_WIFI_DIALOG = '[SETTINGS] OPEN WIFI DIALOG';
export const CLOSE_WIFI_DIALOG = '[SETTINGS] CLOSE WIFI DIALOG';
export const ADD_WIFI = '[SETTINGS] ADD WIFI';
export const REMOVE_WIFI = '[SETTINGS] REMOVE WIFI';
export const SET_EDIT_WIFI_DATA = '[SETTINGS] SET EDIT WIFI DATA';
export const EDIT_WIFI = '[SETTINGS] EDIT WIFI';
export const GET_SETTINGS = '[SETTINGS] GET SETTINGS';
export const GET_WIFI_USAGE = '[SETTINGS] GET WIFI USAGE';
export const GET_CONNECTION_RATIO = '[SETTINGS] GET CONNECTION RATIO';
export const SET_ERROR = '[SETTINGS] SET ERROR';
export const GET_PERSONNEL_SYNCHRONIZATION_HISTORY = '[SETTINGS] GET PERSONNEL SYNCHRONIZATION HISTORY';
export const OPEN_CSV_FILE_FORMAT_DIALOG = '[SETTINGS] OPEN CSV FILE FORMAT DIALOG';
export const CLOSE_CSV_FILE_FORMAT_DIALOG = '[SETTINGS] CLOSE CSV FILE FORMAT DIALOG';
export const OPEN_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG =
  '[SETTINGS] OPEN PERSONNEL SYNCHRONIZATION CHANGE DETAILS DIALOG';
export const CLOSE_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG =
  '[SETTINGS] CLOSE PERSONNEL SYNCHRONIZATION CHANGE DETAILS DIALOG';
export const SET_PERSONNEL_SYNCHRONIZATION_SETTINGS = '[SETTINGS] SET PERSONNEL SYNCHRONIZATION SETTINGS';
export const SET_PERSONNEL_SYNCHRONIZATION_LOADING_TRUE = '[SETTINGS] SET PERSONNEL SYNCHRONIZATION LOADING TRUE';
export const SET_PERSONNEL_SYNCHRONIZATION_LOADING_FALSE = '[SETTINGS] SET PERSONNEL SYNCHRONIZATION LOADING FALSE';
export const SET_TAB = '[SETTINGS] SET TAB';

export function getSettings(facility_id) {
  const request = axios.get('/api/settings/get', {
    params: {
      selectedObid: facility_id
    }
  });
  return dispatch =>
    request
      .then(response => {
        dispatch({
          type: GET_SETTINGS,
          payload: response.data
        });
      })
      .catch(() => {
        dispatch(setError());
      });
}

export function setError() {
  return {
    type: SET_ERROR
  };
}

export function openWifiDialog() {
  return {
    type: OPEN_WIFI_DIALOG
  };
}

export function setEditWifiData(wifi) {
  return {
    type: SET_EDIT_WIFI_DATA,
    wifi: wifi
  };
}

export function closeWifiDialog() {
  return {
    type: CLOSE_WIFI_DIALOG
  };
}

export function removeWifi(ssid, newWifisToSet) {
  const request = axios.post('/api/settings/set', {
    selectedObid: getCurrentSelectedObid(),
    name: 'wifiList',
    value: newWifisToSet
  });

  return dispatch =>
    request.then(() => {
      dispatch({
        type: REMOVE_WIFI,
        payload: ssid
      });
    });
}

export function addWifi(payload) {
  const request = axios.post('/api/settings/set', {
    selectedObid: getCurrentSelectedObid(),
    name: 'wifiList',
    value: {
      [payload.ssid]: {
        password: payload.password,
        security: payload.security,
        hidden: payload.hidden
      },
      ...payload.data
    }
  });

  return dispatch =>
    request.then(() => {
      dispatch({
        type: ADD_WIFI,
        ssid: payload.ssid,
        password: payload.password,
        security: payload.security,
        hidden: payload.hidden
      });
    });
}

export function editWifi(ssid, oldName, password, security, hidden, data) {
  const request = axios.post('/api/settings/set', {
    selectedObid: getCurrentSelectedObid(),
    name: 'wifiList',
    value: data
  });

  return dispatch =>
    request.then(() => {
      dispatch({
        type: EDIT_WIFI,
        oldName,
        ssid,
        password,
        security,
        hidden
      });
    });
}

export function setSettingValue(name, value) {
  axios.post('/api/settings/set', {
    selectedObid: getCurrentSelectedObid(),
    name,
    value
  });

  return {
    type: SET_SETTING_VALUE,
    name,
    value
  };
}

export function setTab(tab) {
  return {
    type: SET_TAB,
    payload: tab
  };
}

export function setPersonnelSynchronizationSettings(url, user, password) {
  axios.post('/api/setPersonnelSynchronizationSettings', {
    selectedObid: getCurrentSelectedObid(),
    url,
    user,
    password
  });

  return {
    type: SET_PERSONNEL_SYNCHRONIZATION_SETTINGS,
    url,
    user,
    password
  };
}

export function getWifiUsage() {
  return dispatch => {
    axios
      .post('/api/devices/getWifiUsage', {
        selectedObid: getCurrentSelectedObid()
      })
      .then(response => {
        dispatch({
          type: GET_WIFI_USAGE,
          payload: response.data
        });
      });
  };
}

export function getConnectionRatio() {
  return dispatch => {
    axios
      .get('/api/reports/getConnectionRatio', {
        params: {
          selectedObid: getCurrentSelectedObid()
        }
      })
      .then(response => {
        dispatch({
          type: GET_CONNECTION_RATIO,
          payload: response.data
        });
      });
  };
}

export function getPersonnelSynchronizationHistory() {
  return dispatch => {
    axios
      .get('/api/getPersonnelSynchronizationHistory', {
        params: {
          selectedObid: getCurrentSelectedObid()
        }
      })
      .then(response => {
        dispatch({
          type: GET_PERSONNEL_SYNCHRONIZATION_HISTORY,
          payload: response.data
        });
      });
  };
}

export function openCsvFileFormatDialog() {
  return {
    type: OPEN_CSV_FILE_FORMAT_DIALOG
  };
}

export function setPersonnelSynchronizationLoadingTrue() {
  return {
    type: SET_PERSONNEL_SYNCHRONIZATION_LOADING_TRUE
  };
}

export function setPersonnelSynchronizationLoadingFalse() {
  return {
    type: SET_PERSONNEL_SYNCHRONIZATION_LOADING_FALSE
  };
}

export function synchronizePersonnel() {
  return dispatch => {
    dispatch(setPersonnelSynchronizationLoadingTrue());
    axios
      .post(
        '/api/synchronizePersonnel',
        {
          selectedObid: getCurrentSelectedObid()
        },
        {
          timeout: null
        }
      )
      .then(() => {
        dispatch(getPersonnelSynchronizationHistory());
        dispatch(setPersonnelSynchronizationLoadingFalse());
      });
  };
}

export function closeCsvFileFormatDialog() {
  return {
    type: CLOSE_CSV_FILE_FORMAT_DIALOG
  };
}

export function openPersonnelSynchronizationDetailsDialog(details) {
  return {
    type: OPEN_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG,
    details
  };
}

export function closePersonnelSynchronizationDetailsDialog() {
  return {
    type: CLOSE_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG
  };
}
