import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { Group, Settings } from '@material-ui/icons';
import React from 'react';

import SettingsLabel from '../SettingsLabel';

const useStyles = makeStyles(() => ({
  tab: {
    fontWeight: 500,
    color: '#3C4252',
    letterSpacing: 0.4,
    fontSize: 14,
    lineHeight: 1.3,
    '@media (max-width:600px)': {
      width: '33%'
    }
  }
}));

function RvmSettingsTabs({ currentTab, onChange, isNextTabsDisabled }) {
  const classes = useStyles();

  return (
    <Tabs textColor='secondary' value={currentTab} onChange={onChange} classes={{ root: 'pl-24' }}>
      <Tab
        className={classes.tab}
        label={<SettingsLabel label='General' icon={<Settings style={{ fontSize: 24 }} />} />}
      />
      <Tab
        disabled={isNextTabsDisabled}
        className={classes.tab}
        label={<SettingsLabel label='Assignments' icon={<Group style={{ fontSize: 24 }} />} />}
      />
    </Tabs>
  );
}

export default RvmSettingsTabs;
