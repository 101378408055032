import _ from '@lodash';

import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  searchText: '',
  selectedEntitiesIds: [],
  routeParams: {},
  loading: true,
  invitationsLoading: true,
  entityDialog: {
    type: 'new',
    props: {
      open: false
    },
    data: null
  }
};

// eslint-disable-next-line complexity
const accessReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USERS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
        loading: false
      };
    }
    case Actions.GET_INVITATIONS: {
      return {
        ...state,
        invitations: action.payload,
        invitationsLoading: false
      };
    }
    case Actions.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true
      };
    }
    case Actions.SET_LOADING_FALSE: {
      return {
        ...state,
        loading: false
      };
    }
    case Actions.SET_MANAGERS_INVITATIONS_LOADING_TRUE: {
      return {
        ...state,
        invitationsLoading: true
      };
    }
    case Actions.SET_MANAGERS_INVITATIONS_LOADING_FALSE: {
      return {
        ...state,
        invitationsLoading: false
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.TOGGLE_IN_SELECTED_ENTITIES: {
      const entityId = action.entityId;

      let selectedEntitiesIds = [...state.selectedEntitiesIds];

      if (selectedEntitiesIds.find(id => id === entityId) !== undefined) {
        selectedEntitiesIds = selectedEntitiesIds.filter(id => id !== entityId);
      } else {
        selectedEntitiesIds = [...selectedEntitiesIds, entityId];
      }

      return {
        ...state,
        selectedEntitiesIds: selectedEntitiesIds
      };
    }
    case Actions.SELECT_ALL_ENTITIES: {
      const arr = Object.keys(state.entities).map(k => state.entities[k]);

      const selectedEntitiesIds = arr.map(entity => entity.id);

      return {
        ...state,
        selectedEntitiesIds: selectedEntitiesIds
      };
    }
    case Actions.DESELECT_ALL_ENTITIES: {
      return {
        ...state,
        selectedEntitiesIds: []
      };
    }
    case Actions.OPEN_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: true
          },
          data: null
        }
      };
    }
    case Actions.CLOSE_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: false
          },
          data: null
        }
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default accessReducer;
