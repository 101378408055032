import _ from '@lodash';

import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  searchText: '',
  routeParams: {},
  error: false
};

// eslint-disable-next-line complexity
const personnelReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ENTITIES: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
        routeParams: action.routeParams,
        loading: false
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.SET_API_ERROR: {
      return {
        ...state,
        error: true
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default personnelReducer;
