import { IconButton } from '@material-ui/core';
import TranslationTooltip from 'app/common-components/TranslationTooltip';
import React from 'react';

function ActionIcon({ icon, title, onClick, isDisabled }) {
  return (
    <TranslationTooltip title={title}>
      <IconButton disabled={isDisabled} onClick={onClick}>
        {icon}
      </IconButton>
    </TranslationTooltip>
  );
}

export default ActionIcon;
