import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { RESET_STORE } from '../store/actions/reduxActions';

export default function GoBackToLoginButton() {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div
      className='cursor-pointer self-start'
      onClick={() => {
        history.push('/login');
        dispatch({
          type: RESET_STORE
        });
      }}
    >
      <KeyboardBackspaceIcon color='primary' fontSize='12' />
    </div>
  );
}
