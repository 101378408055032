import { FuseAnimate } from '@fuse';
import { Typography } from '@material-ui/core';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import EnhancedTranslate from '../../../../common-components/EnhancedTranslate';

function ErrorPage() {
  const dispatch = useDispatch();

  function cleanError() {
    dispatch(Actions.setErrorFalse());
  }

  return (
    <div className='flex flex-1 flex-col items-center justify-center p-16'>
      <div className='max-w-512 text-center'>
        <FuseAnimate animation='transition.expandIn' delay={100}>
          <Typography variant='h1' color='inherit' className='mb-16 font-medium'>
            <EnhancedTranslate value='Error' />
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant='h5' color='textSecondary' className='mb-16'>
            <EnhancedTranslate value='Sorry unexpected error' />
          </Typography>
        </FuseAnimate>

        <Link onClick={cleanError} className='font-medium' to='/summary'>
          <EnhancedTranslate value='Back to the summary' />
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
