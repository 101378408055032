import TranslationTooltip from 'app/common-components/TranslationTooltip';
import React from 'react';

import batteryChargingIcon from '../../../../../../assets/new-icons/battery-charging.svg';

function BatteryChargingTooltip() {
  return (
    <TranslationTooltip title='Connected to electricity'>
      <img alt='' src={batteryChargingIcon} />
    </TranslationTooltip>
  );
}

export default BatteryChargingTooltip;
