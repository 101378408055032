import { SvgIcon } from '@material-ui/core';
import React from 'react';

function RvmIcon(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_24_10)'>
        <path d='M12 5H8V13H12V5Z' />
        <path d='M14.5 9.5C15.3284 9.5 16 8.82843 16 8C16 7.17157 15.3284 6.5 14.5 6.5C13.6716 6.5 13 7.17157 13 8C13 8.82843 13.6716 9.5 14.5 9.5Z' />
        <path d='M15.5 11H13.5V13H15.5V11Z' />
        <path d='M17.5 2H6.5C5.67 2 5 2.67 5 3.5V19.5C5 20.33 5.67 21 6.5 21H7V23H9V21H15V23H17V21H17.5C18.33 21 19 20.33 19 19.5V3.5C19 2.67 18.33 2 17.5 2ZM17.44 19.44H6.56V3.56H17.45V19.45L17.44 19.44Z' />
      </g>
      <defs>
        <clipPath id='clip0_24_10'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default RvmIcon;
