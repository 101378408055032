import RoomIcon from '@material-ui/icons/Room';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openDeviceLocationDialog } from '../../../../store/DevicesSlice';
import ActionIcon from './ActionIcon';

function LocationActionIcon({ device }) {
  const dispatch = useDispatch();

  const handleOpenLocation = ev => {
    ev.stopPropagation();
    dispatch(openDeviceLocationDialog(device));
  };

  return <ActionIcon title='Location' icon={<RoomIcon />} onClick={handleOpenLocation} />;
}

export default LocationActionIcon;
