import { Box } from '@material-ui/core';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import React from 'react';

function SettingsLabel({ label, icon }) {
  return (
    <div>
      {icon}
      <Box display={{ xs: 'none', sm: 'inline' }} className='ml-8'>
        <EnhancedTranslate value={label} />
      </Box>
    </div>
  );
}

export default SettingsLabel;
