import { TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Autocomplete } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import { debounce } from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';

import useAutocompleteService from '../custom-hooks/googleServices/useAutocompleteService';
import EnhancedTranslate from './EnhancedTranslate';
import PoweredByGoogleOption from './PoweredByGoogleOption';

const POWERED_BY_GOOGLE_OPTION = 'poweredByGoogleImg';

export default function GooglePlacesAutocomplete({
  onChange,
  address,
  label,
  types,
  placeholder,
  isError = false,
  helperText = null
}) {
  const [value, setValue] = React.useState(address || '');
  const [options, setOptions] = React.useState([]);

  let autocomplete = useAutocompleteService();

  function getPlacePredictions(input) {
    autocomplete.current.getPlacePredictions(
      {
        input: input,
        types: types
      },
      predictions => {
        setOptions(predictions ? predictions : []);
      }
    );
  }

  const debouncedGetPlacePredictions = useCallback(debounce(getPlacePredictions, 500), []);

  return (
    <Autocomplete
      id='google-map-demo'
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      noOptionsText={<EnhancedTranslate value='No results' />}
      options={options.length > 0 ? [...options, POWERED_BY_GOOGLE_OPTION] : []}
      autoComplete
      freeSolo={value === '' || value === null}
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_event, newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setValue(newInputValue);
        setOptions([]);
        debouncedGetPlacePredictions(newInputValue);
      }}
      getOptionDisabled={option => option === POWERED_BY_GOOGLE_OPTION}
      renderInput={params => (
        <TextField
          {...params}
          label={label ? label : null}
          variant='outlined'
          fullWidth
          placeholder={placeholder}
          error={isError}
          helperText={helperText}
        />
      )}
      renderOption={option => {
        if (option === POWERED_BY_GOOGLE_OPTION) return <PoweredByGoogleOption />;
        const matches = option.structured_formatting.main_text_matched_substrings;

        let parts = [];
        if (matches)
          parts = parse(
            option.structured_formatting.main_text,
            matches.map(match => [match.offset, match.offset + match.length])
          );

        return (
          <div>
            <Grid container alignItems='center'>
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{
                    color: 'text.secondary',
                    mr: 2
                  }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant='body2' color='textSecondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
}
