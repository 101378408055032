import { DEVICE_TYPE_WEBCHAT } from 'app/shared/functions/device-type';
import React from 'react';

import LogsActionIcon from '../icons/LogsActionIcon';
import ShowShadowActionIcon from '../icons/ShowShadowActionIcon';

function WebChatActions({ device, isDisabled, isService, hasPrivacyRestrictions }) {
  return (
    <>
      {isService && <ShowShadowActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {!hasPrivacyRestrictions && <LogsActionIcon serial={device.serial} deviceType={DEVICE_TYPE_WEBCHAT} />}
    </>
  );
}

export default WebChatActions;
