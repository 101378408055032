import * as Actions from '../../actions/fuse/index';
import { RESET_STORE } from '../../actions/reduxActions';

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    autoHideDuration: 6000,
    message: 'Hi',
    variant: null
  }
};

const message = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_MESSAGE: {
      return {
        state: true,
        options: {
          ...initialState.options,
          ...action.options
        }
      };
    }
    case Actions.HIDE_MESSAGE: {
      return {
        ...state,
        state: null
      };
    }
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default message;
