import { DEVICE_TYPE_WATCH } from 'app/shared/functions/device-type';
import React from 'react';

import DeleteActionIcon from '../icons/DeleteActionIcon';
import DownloadLogsActionIcon from '../icons/DownloadLogsActionIcon';
import LocationActionIcon from '../icons/LocationActionIcon';
import LogsActionIcon from '../icons/LogsActionIcon';
import ResetActionIcon from '../icons/ResetActionIcon';
import ScreenShootActionIcon from '../icons/ScreenShootActionIcon';
import ShowShadowActionIcon from '../icons/ShowShadowActionIcon';

function WatchActions({ device, isOnlineDevice, isDisabled, isPartner, isService, hasPrivacyRestrictions }) {
  return (
    <>
      {(isPartner || isService) && <DeleteActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isService && isOnlineDevice && <DownloadLogsActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isOnlineDevice && <ResetActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isOnlineDevice && <ScreenShootActionIcon serial={device.serial} deviceType={DEVICE_TYPE_WATCH} />}
      {isService && <ShowShadowActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {!hasPrivacyRestrictions && (
        <>
          <LocationActionIcon device={device} />
          <LogsActionIcon serial={device.serial} deviceType={DEVICE_TYPE_WATCH} />
        </>
      )}
    </>
  );
}

export default WatchActions;
