import React from 'react';

export const SummaryAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/summary',
      component: React.lazy(() => import('app/main/apps/summary/products/Summary'))
    }
  ]
};
