import HistoryIcon from '@material-ui/icons/History';
import React from 'react';
import { useHistory } from 'react-router';

import ActionIcon from './ActionIcon';

function LogsActionIcon({ serial, deviceType }) {
  const history = useHistory();

  const handleOpenLogs = ev => {
    ev.stopPropagation();
    history.push(`/devices/history/${serial}/${deviceType}`);
  };

  return <ActionIcon title='Logs' icon={<HistoryIcon />} onClick={handleOpenLogs} />;
}

export default LogsActionIcon;
