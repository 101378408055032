import React from 'react';

export const MigrateObjectsBetweenEnvironmentsAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/migrateObjectsBetweenEnvironments',
      component: React.lazy(() =>
        import('app/main/apps/migrateObjectBetweenEnvironments/MigrateObjectsBetweenEnvironmentsApp')
      )
    }
  ]
};
