import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ThemeProvider from '@material-ui/styles/ThemeProvider/ThemeProvider';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LANGUAGES, getCurrentWebsiteLanguage } from '../../../lib/languages';
import { setTranslationsForLanguage } from '../../store/actions';
import Flag from '../Icons/Flag';

function TranslationsLanguagesSelect() {
  const dispatch = useDispatch();

  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);

  const [selectedLanguage, setSelectedLanguage] = useState(getCurrentWebsiteLanguage());
  const [anchorLanguagePopover, setAnchorLanguagePopover] = useState(null);
  const openLanguagesPopover = Boolean(anchorLanguagePopover);

  function onChange(lang) {
    setSelectedLanguage(lang);
    dispatch(setTranslationsForLanguage(lang.code));
    localStorage.setItem('language', lang.code);
    document.documentElement.lang = lang.code;
    handlePopoverLanguagesClose();
  }

  function handlePopoverLanguagesClose() {
    setAnchorLanguagePopover(null);
  }

  function handlePopoverLanguagesOpen(event) {
    setAnchorLanguagePopover(event.currentTarget);
  }

  return (
    <React.Fragment>
      {selectedLanguage && (
        <React.Fragment>
          <div
            onClick={handlePopoverLanguagesOpen}
            style={{
              cursor: 'pointer'
            }}
          >
            <Flag lang={selectedLanguage} />
            {'\u00A0'}
            {'\u00A0'}
            {selectedLanguage.originalName}
            <ArrowDropDownIcon
              style={{
                marginBottom: '-7px',
                verticalAlign: 'baseline'
              }}
            />
          </div>
          <Popover
            id='mouse-over-popover'
            open={openLanguagesPopover}
            anchorEl={anchorLanguagePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverLanguagesClose}
            disableRestoreFocus
          >
            <ThemeProvider theme={mainTheme}>
              {LANGUAGES.map(lang => (
                <div key={lang.code} className='flex'>
                  <MenuItem
                    style={{
                      width: '100%'
                    }}
                    onClick={() => {
                      onChange(lang);
                    }}
                  >
                    <Flag lang={lang} />
                    {'\u00A0'}
                    {'\u00A0'}
                    {lang.originalName}
                  </MenuItem>
                </div>
              ))}
            </ThemeProvider>
          </Popover>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default React.memo(TranslationsLanguagesSelect);
