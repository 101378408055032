import { Checkbox, MenuItem, TextField, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LocationOn, SupervisorAccount } from '@material-ui/icons';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EnhancedTranslate from '../../../../common-components/EnhancedTranslate';
import { setInButtonSettingsForm } from '../store/DevicesSlice';

const useStyles = makeStyles(() => ({
  icon: {
    color: '#767575',
    width: 32,
    height: 32
  }
}));

export default function ButtonGeneralSettingsForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const jobTitles = useSelector(({ forSelect }) => forSelect.forSelect.jobTitlesWithUsers);
  const { buttonSettingsForm } = useSelector(state => state.devicesSlice);

  return (
    <Fragment>
      <div style={{ overflow: 'auto', paddingTop: 32, paddingInlineEnd: 16 }}>
        <div className='flex'>
          <div className='min-w-48 self-center'>
            <LocationOn className={clsx(classes.icon)} />
          </div>
          <TextField
            label={
              <div className='flex'>
                <div>
                  <EnhancedTranslate value='Location of button' />
                </div>
              </div>
            }
            autoFocus
            error={buttonSettingsForm.name === ''}
            helperText={buttonSettingsForm.name === '' ? <EnhancedTranslate value='Fill this field' /> : ''}
            name='name'
            variant='outlined'
            value={buttonSettingsForm.name}
            onChange={e => {
              dispatch(
                setInButtonSettingsForm({
                  key: 'name',
                  value: e.target.value
                })
              );
            }}
            fullWidth
          />
        </div>

        <div className='flex' style={{ marginTop: 32 }}>
          <div className='min-w-48 self-center'>
            <SupervisorAccount className={clsx(classes.icon)} />
          </div>
          <TextField
            label={
              <div className='flex'>
                <div>
                  <EnhancedTranslate value='Notify about unhandled tasks' />
                </div>
              </div>
            }
            select
            variant='outlined'
            value={buttonSettingsForm.jobTitleToInformAboutUnhandledTasks || -1}
            onChange={event => {
              dispatch(
                setInButtonSettingsForm({
                  key: 'jobTitleToInformAboutUnhandledTasks',
                  value: event.target.value
                })
              );
            }}
            fullWidth
          >
            <MenuItem value={-1}>
              <EnhancedTranslate value='Do not inform' />
            </MenuItem>
            {jobTitles &&
              jobTitles.map(jobTitle => (
                <MenuItem key={jobTitle.id} value={jobTitle.id}>
                  {jobTitle.name}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className='flex' style={{ marginTop: 32 }}>
          <FormControlLabel
            label={
              <EnhancedTranslate value='Notify Secondary Assignees immediately if only 1 person is available in Primary Assignees' />
            }
            control={
              <Checkbox
                style={{ marginRight: 16, marginLeft: 8 }}
                onClick={event => {
                  event.stopPropagation();
                  dispatch(
                    setInButtonSettingsForm({
                      key: 'informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle',
                      value: event.target.checked
                    })
                  );
                }}
                checked={!!buttonSettingsForm.informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle}
              />
            }
          />
          <label htmlFor='check3'>
            <span className='fa fa-check' />
          </label>
        </div>
      </div>
      <div />
    </Fragment>
  );
}
