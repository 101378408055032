import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedObjectName } from '../../lib/commonFunctions';

export const useDocumentTitle = title => {
  const obid = useSelector(({ common }) => common.common.obid);

  useEffect(() => {
    const objectName = getSelectedObjectName();
    if (objectName) document.title = `${title} | ${objectName} | cDashboard`;
    else document.title = `${title} | cDashboard`;
  }, [title, obid]);
};
