import { makeStyles } from '@material-ui/core';
import React from 'react';

import useAssigneesPanels from '../../../hooks/useAssigneesPanels';
import AssigneesPanel from '../../AssigneesPanel';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';

const ASSIGNEE_TYPES = [PRIMARY, SECONDARY];

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 80,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBlock: 32,
    paddingInline: 16,
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: 16
    }
  }
}));

function RvmSettingsAssignmentsTab({
  primaryJobTitleIds,
  primaryUserIds,
  secondaryJobTitleIds,
  secondaryUserIds,
  updateForm
}) {
  const classes = useStyles();

  const { getUserIdsForManyJobTitle } = useAssigneesPanels();

  const generatePanelData = type => {
    const isPrimary = type === PRIMARY;
    const oppositeType = isPrimary ? SECONDARY : PRIMARY;

    return {
      title: isPrimary ? 'Primary Assignees' : 'Secondary Assignees',
      tooltip: `rvm-${type}-assignees-info`,
      selectedJobTitles: isPrimary ? primaryJobTitleIds : secondaryJobTitleIds,
      selectedUsers: isPrimary ? primaryUserIds : secondaryUserIds,
      disabledJobTitles: isPrimary ? secondaryJobTitleIds : primaryJobTitleIds,
      disabledUsers: [
        ...(isPrimary ? secondaryUserIds : primaryUserIds),
        ...getUserIdsForManyJobTitle(isPrimary ? secondaryJobTitleIds : primaryJobTitleIds)
      ],
      onJobTitleCheck: newValue => updateForm(`${type}_job_title_ids`, newValue),
      onUserCheck: newValue => updateForm(`${type}_user_ids`, newValue),
      updatedDisabledUserIds: newValue => updateForm(`${oppositeType}_user_ids`, newValue)
    };
  };

  return (
    <div className={classes.container}>
      {ASSIGNEE_TYPES.map((type, index) => {
        const panelData = generatePanelData(type);
        return (
          <AssigneesPanel
            key={index}
            title={panelData.title}
            tooltip={panelData.tooltip}
            selectedJobTitles={panelData.selectedJobTitles}
            selectedUsers={panelData.selectedUsers}
            disabledJobTitles={panelData.disabledJobTitles}
            disabledUsers={panelData.disabledUsers}
            onJobTitleCheck={panelData.onJobTitleCheck}
            onUserCheck={panelData.onUserCheck}
            updatedDisabledUserIds={panelData.updatedDisabledUserIds}
          />
        );
      })}
    </div>
  );
}

export default RvmSettingsAssignmentsTab;
