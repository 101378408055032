export const DEVICE_TYPE_ALL = 'all';
export const DEVICE_TYPE_WATCH = 'watch';
export const DEVICE_TYPE_WEBCHAT = 'webchat';
export const DEVICE_TYPE_UNRECOGNIZED = 'unrecognized';
export const DEVICE_TYPE_TABLET = 'tablet';
export const DEVICE_TYPE_BUTTON = 'button';
export const DEVICE_TYPE_RVM = 'reverse_vending_machine';

export function isNumber(serial) {
  return /^\d+$/.test(serial);
}

function isMacAddress(serial) {
  const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  return regex.test(serial);
}

export function getDeviceTypeFromSerial(serial) {
  if (serial.length === 15 && isNumber(serial)) return DEVICE_TYPE_WATCH;
  if (serial.length < 3) return DEVICE_TYPE_WEBCHAT;
  if (serial.length === 5 && serial.charAt(0) === 'C') return DEVICE_TYPE_TABLET;
  if (isMacAddress(serial)) return DEVICE_TYPE_TABLET;
  if (serial.substring(0, 2) === 'btn') return DEVICE_TYPE_BUTTON;
  return DEVICE_TYPE_UNRECOGNIZED;
}

export function getDeviceType(device) {
  if (!device) return null;

  if (device.deviceType) return device.deviceType;

  return device?.deviceShadowReported?.deviceType ?? getDeviceTypeFromSerial(device.serial);
}

export function filterByType(devices, typeToShow) {
  const deviceTypes = [DEVICE_TYPE_WATCH, DEVICE_TYPE_TABLET, DEVICE_TYPE_WEBCHAT, DEVICE_TYPE_BUTTON, DEVICE_TYPE_RVM];

  if (typeToShow === DEVICE_TYPE_ALL) return devices;
  if (!deviceTypes.includes(typeToShow)) return [];

  return devices && devices.filter(device => isDeviceType(device, typeToShow));
}

export const isDeviceType = (device, type) => {
  const deviceType = device?.deviceShadowReported?.deviceType ?? getDeviceType(device);
  return type === deviceType;
};

export const isWatch = device => isDeviceType(device, DEVICE_TYPE_WATCH);

export const isWebChat = device => isDeviceType(device, DEVICE_TYPE_WEBCHAT);

export const isButton = device => isDeviceType(device, DEVICE_TYPE_BUTTON);

export const isRvm = device => isDeviceType(device, DEVICE_TYPE_RVM);

export const deviceHasAnyTypeValid = (device, types) => types.some(type => isDeviceType(device, type));
