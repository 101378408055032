import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAssigneesPanels from '../hooks/useAssigneesPanels';
import { setInButtonSettingsForm } from '../store/DevicesSlice';
import AssigneesPanel from './AssigneesPanel';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';

const ASSIGNEE_TYPES = [PRIMARY, SECONDARY];

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 80,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBlock: 32,
    paddingInline: 16,
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: 16
    }
  }
}));

export default function ButtonAssignmentsSettingsForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { buttonSettingsForm } = useSelector(state => state.devicesSlice);
  const { getUserIdsForManyJobTitle } = useAssigneesPanels();

  const { primaryJobTitles, primaryUids, secondaryJobTitles, secondaryUids } = buttonSettingsForm;

  const updateButtonSettingsForm = (key, value) => {
    dispatch(
      setInButtonSettingsForm({
        key,
        value
      })
    );
  };

  const generatePanelData = type => {
    const isPrimary = type === PRIMARY;
    const oppositeType = isPrimary ? SECONDARY : PRIMARY;

    return {
      title: isPrimary ? 'Primary Assignees' : 'Secondary Assignees',
      tooltip: `${type}-assigness-info`,
      selectedJobTitles: isPrimary ? primaryJobTitles : secondaryJobTitles,
      selectedUsers: isPrimary ? primaryUids : secondaryUids,
      disabledJobTitles: isPrimary ? secondaryJobTitles : primaryJobTitles,
      disabledUsers: [
        ...(isPrimary ? secondaryUids : primaryUids),
        ...getUserIdsForManyJobTitle(isPrimary ? secondaryJobTitles : primaryJobTitles)
      ],
      onJobTitleCheck: newValue => updateButtonSettingsForm(`${type}JobTitles`, newValue),
      onUserCheck: newValue => updateButtonSettingsForm(`${type}Uids`, newValue),
      updatedDisabledUserIds: newValue => updateButtonSettingsForm(`${oppositeType}Uids`, newValue)
    };
  };

  return (
    <div className={classes.container}>
      {ASSIGNEE_TYPES.map((type, index) => {
        const panelData = generatePanelData(type);
        return (
          <AssigneesPanel
            key={index}
            title={panelData.title}
            tooltip={panelData.tooltip}
            selectedJobTitles={panelData.selectedJobTitles}
            selectedUsers={panelData.selectedUsers}
            disabledJobTitles={panelData.disabledJobTitles}
            disabledUsers={panelData.disabledUsers}
            onJobTitleCheck={panelData.onJobTitleCheck}
            onUserCheck={panelData.onUserCheck}
            updatedDisabledUserIds={panelData.updatedDisabledUserIds}
          />
        );
      })}
    </div>
  );
}
