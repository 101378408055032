import { I18n } from 'react-redux-i18n';

export function useReactTableTranslations() {
  return {
    previousText: I18n.t('Previous'),
    nextText: I18n.t('Next'),
    loadingText: I18n.t('Loading'),
    noDataText: I18n.t('There are no data!'),
    pageText: I18n.t('Page'),
    ofText: I18n.t('of'),
    rowsText: I18n.t('rows')
  };
}
