import { SvgIcon } from '@material-ui/core';
import React from 'react';

function BottleIcon(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path d='M14.98 12C14.93 9.94 13 7.99 13 5V3H11V5C11 7.99 9.06 9.94 9.02 12C8.97 14.13 8.99 18.29 9.01 20.52C9.01 21.34 9.69 22 10.51 22H13.5C14.32 22 14.99 21.34 15 20.52C15.02 18.3 15.04 14.14 14.99 12H14.98ZM14 19.5H10V12.5H14V19.5Z' />
    </SvgIcon>
  );
}

export default BottleIcon;
