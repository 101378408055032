import { useRef } from 'react';

export default function useGeocoderService() {
  const geocoderService = useRef();

  if (!geocoderService.current) {
    geocoderService.current = new window.google.maps.Geocoder();
  }
  return geocoderService;
}
