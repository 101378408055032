import { AppBar, Typography, makeStyles } from '@material-ui/core';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import BottleIcon from 'app/common-components/Icons/BottleIcon';
import React from 'react';

const useStyles = makeStyles(() => ({
  deviceInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 16,
    '@media (min-width:600px)': {
      flexDirection: 'row',
      width: '100%',
      gap: 32
    }
  }
}));

function RvmSettingsDialogHeader({ name, serial, isLoading }) {
  const classes = useStyles();

  return (
    <AppBar position='static' elevation={1}>
      <div className='p-24'>
        <div className='flex'>
          <div className='self-center'>
            <BottleIcon className='text-28' />
          </div>
          <Typography color='inherit' className='ml-10 typography-h6'>
            <EnhancedTranslate value='RVM settings' />
          </Typography>
        </div>
        <div className={classes.deviceInfo}>
          <div className='flex gap-8'>
            <Typography className='typography-subtitle2'>
              <EnhancedTranslate value='Device name' />:
            </Typography>
            <Typography className='typography-subtitle2'>
              {name === '' ? <EnhancedTranslate value={isLoading ? 'Loading' : 'None'} /> : name}
            </Typography>
          </div>
          <div className='flex gap-8'>
            <Typography className='typography-subtitle2'>
              <EnhancedTranslate value='Serial' />:
            </Typography>
            {isLoading ? (
              <EnhancedTranslate value='Loading' />
            ) : (
              <Typography className='typography-subtitle2'>{serial}</Typography>
            )}
          </div>
        </div>
      </div>
    </AppBar>
  );
}

export default RvmSettingsDialogHeader;
