import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import React from 'react';
import { useDispatch } from 'react-redux';

import ActionIcon from './ActionIcon';

function SimulationActionIcon({ serial, isDisabled }) {
  const dispatch = useDispatch();

  const handleSimulateButtonClick = ev => {
    ev.stopPropagation();
    dispatch(Actions.simulateButtonClick(serial));
  };

  return (
    <ActionIcon
      title='Click simulation'
      icon={<ArrowRightIcon />}
      isDisabled={isDisabled}
      onClick={handleSimulateButtonClick}
    />
  );
}

export default SimulationActionIcon;
