import { makeStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openRecommendDialog } from './store/recommendDialogSlice';

const useStyles = makeStyles({
  background: {
    borderTop: '1px solid #D9D9D9',
    borderRight: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
    borderLeft: 'none',
    color: '#f3f3f3',
    transition: 'background-color 0.5s, color 0.5s',
    '&:hover': {
      backgroundColor: '#D9D9D9',
      color: 'rgb(60, 66, 82)',
      fontWeight: 'bold',
      '& $icon': {
        color: 'red',
        transform: 'scale(1.25)',
        transition: 'color 0.5s, transform 0.5s'
      }
    }
  },
  icon: {
    fontSize: 16
  }
});

function OpenRecommendDialogButton() {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.background,
        'pl-24 py-8 mr-16 rounded-r-full flex items-center gap-10 cursor-pointer recommend-button'
      )}
      onClick={() => dispatch(openRecommendDialog())}
    >
      <FavoriteIcon className={classes.icon} />

      <div className='pt-1 recommend-button-text whitespace-no-wrap'>
        <EnhancedTranslate value='recommend cwatch' />
      </div>
    </div>
  );
}
export default OpenRecommendDialogButton;
