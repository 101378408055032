import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

const initialState = {
  data: null,
  archivedPings: null,
  archivedPingsCount: null,
  loading: true
};

const archivedPingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_DEVICES_HISTORY: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case Actions.SET_DEVICES_HISTORY_LOADING_TRUE: {
      return {
        ...state,
        loading: true
      };
    }
    case Actions.SET_DEVICES_HISTORY_LOADING_FALSE: {
      return {
        ...state,
        loading: false
      };
    }
    case Actions.GET_DEVICES_PINGS_COUNT: {
      return {
        ...state,
        archivedPingsCount: action.payload.allPingsCount
      };
    }
    case Actions.GET_DEVICES_PINGS: {
      return {
        ...state,
        archivedPings: action.payload,
        loading: false
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default archivedPingsReducer;
