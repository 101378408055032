import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SETTING_TYPE_BOOLEAN,
  SETTING_TYPE_COMMA_SEPARATED_STRING,
  SETTING_TYPE_INT,
  SETTING_TYPE_STRING
} from '../../lib/constants';
import * as Actions from '../main/apps/settings/store/actions';

export function useSetting(settingName, settingType, defaultValue = null) {
  function handleChange(value) {
    if (settingType === SETTING_TYPE_BOOLEAN)
      dispatch(Actions.setSettingValue(settingName, value === true ? '1' : '0'));
    else if (settingType === SETTING_TYPE_STRING || settingType === SETTING_TYPE_INT)
      dispatch(Actions.setSettingValue(settingName, value));
    else if (settingType === SETTING_TYPE_COMMA_SEPARATED_STRING)
      dispatch(Actions.setSettingValue(settingName, value.join(',')));

    setSetting(value);
  }

  function getValue() {
    let value = settings[settingName];

    if (value !== null) {
      if (settingType === SETTING_TYPE_BOOLEAN) {
        return value === '1';
      } else if (settingType === SETTING_TYPE_STRING) {
        return value;
      } else if (settingType === SETTING_TYPE_INT) {
        return parseInt(value);
      } else if (settingType === SETTING_TYPE_COMMA_SEPARATED_STRING) {
        return value.split(',');
      }
    } else return defaultValue;
  }

  const dispatch = useDispatch();
  const settings = useSelector(({ settings }) => settings.settings);
  const [setting, setSetting] = useState(getValue());

  useEffect(() => {
    setSetting(getValue());
  }, [settings]);

  return [setting, handleChange];
}
