import { ThemeProvider } from '@material-ui/styles';
import reducer from 'app/main/apps/settings/store/reducers/settings.reducer.js';
import React from 'react';
import { useSelector } from 'react-redux';

import withReducer from '../../../app/store/withReducer';

function FuseTheme(props) {
  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);

  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
}

export default withReducer('settings', reducer)(React.memo(FuseTheme));
