import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransitionComponent } from '../../../lib/commonFunctions';
import { setInformationAboutTermsOfServiceDialogOpen } from '../../auth/store/LoginSlice';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';

const useStyles = makeStyles(() => ({
  textContainer: {
    textAlign: 'justify'
  }
}));

export default function InformationAboutTermsOfServiceDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = useSelector(state => state.login.informationAboutTermsOfServiceDialogOpen);

  function closeDialog() {
    dispatch(setInformationAboutTermsOfServiceDialogOpen(false));
  }

  return (
    <Dialog open={open} TransitionComponent={TransitionComponent} onClose={closeDialog} fullWidth maxWidth='xs'>
      <div className='p-16'>
        <div className={classes.textContainer}>
          <EnhancedTranslate value='tos-info-for-old-users' />
        </div>
        <div className='mt-16 flex justify-center'>
          <div>
            <Button onClick={closeDialog} color='primary' variant='outlined'>
              <EnhancedTranslate value='OK' />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
