import { FuseScrollbars } from '@fuse';
import { AppBar, CircularProgress, Dialog, DialogContent, Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import { Edit, Group, RadioButtonChecked, Settings } from '@material-ui/icons';
import * as Actions from 'app/main/apps/devices/store/actions';
import { setButtonSettings } from 'app/main/apps/devices/store/actions';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransitionComponent } from '../../../../../../lib/commonFunctions';
import { SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING } from '../../../../../../lib/constants';
import EnhancedTranslate from '../../../../../common-components/EnhancedTranslate';
import { useSetting } from '../../../../../custom-hooks/useSetting';
import * as ForSelectActions from '../../../../../store/actions';
import SettingsDialogActions from '../../SettingsDialogActions';
import {
  fetchDefaultButtonTranslations,
  setButtonSettingsForm,
  setDefaultButtonSettingsForm,
  setDefaultButtonTranslations
} from '../../store/DevicesSlice';
import ButtonAssignmentsSettingsForm from '../ButtonAssignmentsSettingsForm';
import ButtonGeneralSettingsForm from '../ButtonGeneralSettingsForm';
import ButtonLabelsSettingsForm from '../ButtonLabelsSettingsForm';
import SettingsLabel from './SettingsLabel';

const TAB_GENERAL = 0;
const TAB_ASSIGNMENTS = 1;
const TAB_LABELS = 2;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  tab: {
    fontWeight: 500,
    color: '#3C4252',
    letterSpacing: 0.4,
    fontSize: 14,
    lineHeight: 1.3,
    '@media (max-width:600px)': {
      width: '33%'
    }
  },
  deviceInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 16,
    '@media (min-width:600px)': {
      flexDirection: 'row',
      width: '100%',
      gap: 32
    }
  }
}));

export default function ButtonSettingsDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { buttonSettingsDialogOpen, buttonSettingsDialogSerial, buttonSettingsLoading } = useSelector(
    ({ devices }) => devices.devices
  );
  const button = useSelector(({ devices }) => devices.devices.buttonSettings);
  const { buttonSettingsForm, defaultButtonTranslationsLoading } = useSelector(state => state.devicesSlice);

  const [currentlySelectedTab, setCurrentlySelectedTab] = useState(TAB_GENERAL);

  const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_COMMA_SEPARATED_STRING, []);

  useEffect(() => {
    dispatch(ForSelectActions.getJobTitlesWithUsers());
  }, [dispatch]);

  useEffect(() => {
    if (buttonSettingsDialogOpen) {
      dispatch(Actions.getButtonSettings(buttonSettingsDialogSerial));
      dispatch(fetchDefaultButtonTranslations());
    }
  }, [buttonSettingsDialogOpen, dispatch, buttonSettingsDialogSerial]);

  useEffect(() => {
    if (buttonSettingsDialogOpen) {
      initForm();
      setCurrentlySelectedTab(TAB_GENERAL);
    }
  }, [buttonSettingsDialogOpen, button, buttonLanguages]);

  const isLoading = buttonSettingsLoading || defaultButtonTranslationsLoading;

  function initForm() {
    if (button)
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          ...button,
          jobTitleToInformAboutUnhandledTasks: button.jobTitleToInformAboutUnhandledTasks || -1,
          selectedLanguage: buttonLanguages[0]
        })
      );
    else {
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          selectedLanguage: buttonLanguages[0]
        })
      );
    }
  }

  function closeComposeDialog() {
    if (isLoading) return;

    dispatch(Actions.closeButtonSettingsDialog());
    dispatch(setDefaultButtonTranslations(null));
    dispatch(setDefaultButtonSettingsForm());
  }

  function submit() {
    let requestPayload = {
      name: buttonSettingsForm.name.trim(),
      jobTitleToInformAboutUnhandledTasks: buttonSettingsForm.jobTitleToInformAboutUnhandledTasks,
      primaryJobTitles: buttonSettingsForm.primaryJobTitles,
      secondaryJobTitles: buttonSettingsForm.secondaryJobTitles,
      primaryUids: buttonSettingsForm.primaryUids,
      secondaryUids: buttonSettingsForm.secondaryUids,
      informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle:
        buttonSettingsForm.informSecondaryJobTitlesImmediatelyWhen1PersonOnPrimaryJobTitle
    };

    if (Object.keys(buttonSettingsForm.modifiedTranslationsByLanguage).length > 0) {
      requestPayload['translationsByLanguage'] = buttonSettingsForm.modifiedTranslationsByLanguage;
    }

    dispatch(setDefaultButtonTranslations(null));
    dispatch(setDefaultButtonSettingsForm());
    dispatch(setButtonSettings(buttonSettingsDialogSerial, requestPayload));
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return buttonSettingsForm.name?.length > 0;
  }

  if (!buttonSettingsDialogOpen) return null;

  return (
    <Dialog
      open={buttonSettingsDialogOpen}
      TransitionComponent={TransitionComponent}
      onClose={closeComposeDialog}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      classes={{ paper: classes.dialogPaper }}
    >
      <AppBar position='static' elevation={1}>
        <div className='p-24'>
          <div className='flex'>
            <div className='self-center'>
              <RadioButtonChecked />
            </div>
            <Typography color='inherit' className='ml-10 typography-h6'>
              <EnhancedTranslate value='button settings' />
            </Typography>
          </div>
          <div className={classes.deviceInfo}>
            <div className='flex gap-8'>
              <Typography className='typography-subtitle2'>
                <EnhancedTranslate value='Location' />:
              </Typography>
              <Typography className='typography-subtitle2'>
                {button && <>{button.name === '' ? <EnhancedTranslate value='None' /> : button.name}</>}
              </Typography>
            </div>
            <div className='flex gap-8'>
              <Typography className='typography-subtitle2'>
                <EnhancedTranslate value='Serial' />:
              </Typography>
              <Typography className='typography-subtitle2'>{buttonSettingsDialogSerial}</Typography>
            </div>
          </div>
        </div>
      </AppBar>

      <Tabs
        textColor='secondary'
        value={currentlySelectedTab}
        onChange={(_e, tabValue) => {
          setCurrentlySelectedTab(tabValue);
        }}
        classes={{ root: 'pl-24' }}
      >
        <Tab
          className={clsx(classes.tab)}
          label={<SettingsLabel label='General' icon={<Settings style={{ fontSize: 24 }} />} />}
        />
        <Tab
          disabled={buttonSettingsForm.name === '' || isLoading}
          className={clsx(classes.tab)}
          label={<SettingsLabel label='Assignments' icon={<Group style={{ fontSize: 24 }} />} />}
        />
        <Tab
          disabled={buttonSettingsForm.name === '' || isLoading}
          className={clsx(classes.tab)}
          label={<SettingsLabel label='Customize' icon={<Edit style={{ fontSize: 24 }} />} />}
        />
      </Tabs>
      <DialogContent className='p-0'>
        {isLoading ? (
          <div className='flex h-full w-full justify-center items-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <FuseScrollbars className='flex justify-between flex-col h-full p-24 pt-0'>
            {currentlySelectedTab === TAB_GENERAL && <ButtonGeneralSettingsForm />}
            {currentlySelectedTab === TAB_ASSIGNMENTS && <ButtonAssignmentsSettingsForm />}
            {currentlySelectedTab === TAB_LABELS && <ButtonLabelsSettingsForm />}
            <SettingsDialogActions
              onSubmit={submit}
              canBeSubmitted={canBeSubmitted()}
              closeDialog={closeComposeDialog}
            />
          </FuseScrollbars>
        )}
      </DialogContent>
    </Dialog>
  );
}
