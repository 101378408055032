import axios from 'axios';
import { STATUS_FAILED, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCEEDED } from 'lib/constants';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  status: STATUS_INITIAL,
  isSettingsDialogOpen: false,
  rvm: {
    external_id: null,
    id: null,
    name: '',
    primary_job_title_ids: [],
    primary_user_ids: [],
    secondary_job_title_ids: [],
    secondary_user_ids: []
  }
};

export const fetchRvmSettings = createAsyncThunk('RvmDialog/fetch', async ({ facilityId, rvmId }) => {
  const { data } = await axios.get(`/api/facilities/${facilityId}/reverse-vending-machine/${rvmId}`);

  return data;
});

const rvmSlice = createSlice({
  name: 'RVM',
  initialState,
  reducers: {
    openRvmSettingsDialog: state => {
      state.isSettingsDialogOpen = true;
    },
    closeRvmDialog: state => {
      state.isSettingsDialogOpen = false;
    },
    updateRvm: (state, action) => {
      state.rvm = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRvmSettings.pending, state => {
        state.status = STATUS_LOADING;
        state.rvm = initialState.rvm;
      })
      .addCase(fetchRvmSettings.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.rvm = action.payload;
      })
      .addCase(fetchRvmSettings.rejected, state => {
        state.status = STATUS_FAILED;
        state.rvm = initialState.rvm;
        state.isSettingsDialogOpen = false;
      });
  }
});

export const { closeRvmDialog, openRvmSettingsDialog, updateRvm } = rvmSlice.actions;

export default rvmSlice.reducer;
