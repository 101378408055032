import { AddDevicesFromPackageRedirectorAppConfig } from './addDevicesFromPackage/AddDevicesFromPackageRedirectorAppConfig';
import { ChannelsAppConfig } from './channels/ChannelsAppConfig';
import { PredefinedMessagesAppConfig } from './communication/predefinedMessages/PredefinedMessagesAppConfig';
import { DashboardAccessAppConfig } from './dashboardAccess/DashboardAccessAppConfig';
import { DevicesAppConfig } from './devices/DevicesAppConfig';
import { DevicesAssignmentHistoryAppConfig } from './devicesAssignmentHistory/DevicesAssignmentHistoryAppConfig';
import { MigrateObjectsBetweenEnvironmentsAppConfig } from './migrateObjectBetweenEnvironments/MigrateObjectsBetweenEnvironmentsAppConfig';
import { PartnerAppConfig } from './partner/PartnerAppConfig';
import { PersonnelAppConfig } from './personnel/PersonnelAppConfig';
import { PersonnelOnlineAppConfig } from './personnelOnline/PersonnelOnlineAppConfig';
import { ButtonsReportsAppConfig } from './reports/Buttons/ButtonsReportsAppConfig';
import { CommunicationConfig } from './reports/Communication/CommunicationConfig';
import { CwatchReportsConfig } from './reports/CwatchReports/CwatchReportsConfig';
import { WorkRegisterReportsConfig } from './reports/WorkRegister/WorkRegisterReportsConfig';
import { RmaAppConfig } from './rma/RmaAppConfig';
import { SettingsAppConfig } from './settings/SettingsAppConfig';
import { SummaryAppConfig } from './summary/SummaryAppConfig';

export const appsConfigs = [
  ButtonsReportsAppConfig,
  DashboardAccessAppConfig,
  PartnerAppConfig,
  PersonnelOnlineAppConfig,
  PersonnelAppConfig,
  SummaryAppConfig,
  DevicesAppConfig,
  SettingsAppConfig,
  PredefinedMessagesAppConfig,
  ChannelsAppConfig,
  CommunicationConfig,
  CwatchReportsConfig,
  WorkRegisterReportsConfig,
  DevicesAssignmentHistoryAppConfig,
  AddDevicesFromPackageRedirectorAppConfig,
  MigrateObjectsBetweenEnvironmentsAppConfig,
  RmaAppConfig
];
