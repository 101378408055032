import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';

import EnhancedTranslate from '../../../common-components/EnhancedTranslate';

export default function SettingsDialogActions({ onSubmit, canBeSubmitted, closeDialog, isPending = false }) {
  return (
    <DialogActions style={{ padding: 0, paddingTop: 12 }}>
      <Button color='secondary' variant='outlined' onClick={closeDialog} disabled={isPending}>
        <EnhancedTranslate value='Cancel' />
      </Button>
      <Button
        style={{ marginLeft: 24 }}
        variant='contained'
        color='secondary'
        disabled={!canBeSubmitted || isPending}
        onClick={onSubmit}
      >
        <EnhancedTranslate value='Save' />
      </Button>
    </DialogActions>
  );
}
