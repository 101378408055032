import { FuseScrollbars } from '@fuse';
import { CircularProgress, DialogContent } from '@material-ui/core';
import React from 'react';

import RvmSettingsAssignmentsTab from './RvmSettingsAssignmentsTab';
import RvmSettingsGeneralTab from './RvmSettingsGeneralTab';

export const RVM_TAB_GENERAL = 0;
const RVM_TAB_ASSIGNMENTS = 1;

function RvmSettingsDialogContent({ currentTab, isLoading, formData, updateForm }) {
  const onUpdateName = value => {
    updateForm('name', value);
  };

  return (
    <DialogContent className='p-0'>
      {isLoading ? (
        <div className='flex h-full w-full justify-center items-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <FuseScrollbars className='flex justify-between flex-col h-full p-24 pt-0'>
          {currentTab === RVM_TAB_GENERAL && <RvmSettingsGeneralTab name={formData.name} onChange={onUpdateName} />}
          {currentTab === RVM_TAB_ASSIGNMENTS && (
            <RvmSettingsAssignmentsTab
              primaryJobTitleIds={formData.primary_job_title_ids}
              primaryUserIds={formData.primary_user_ids}
              secondaryJobTitleIds={formData.secondary_job_title_ids}
              secondaryUserIds={formData.secondary_user_ids}
              updateForm={updateForm}
            />
          )}
        </FuseScrollbars>
      )}
    </DialogContent>
  );
}

export default RvmSettingsDialogContent;
