import { Button, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COLOR_CHARCOAL_100 } from '../../../../../../lib/colors';
import { getLangName } from '../../../../../../lib/languages';
import EnhancedTranslate from '../../../../../common-components/EnhancedTranslate';
import Flag from '../../../../../common-components/Icons/Flag';
import HelpIcon from '../../../../../common-components/Icons/HelpIcon';
import { setInButtonSettingsForm } from '../../store/DevicesSlice';

const useStyles = makeStyles(() => ({
  infoLabel: {
    fontWeight: 400,
    fontSize: '12px'
  },
  selected: {
    background: '#E8E9ED'
  },
  buttonGroup: {
    marginTop: '8px',
    display: 'flex'
  },
  button: {
    textDecoration: 'none',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  buttonLabel: {
    color: COLOR_CHARCOAL_100,
    fontSize: '12px'
  },
  helpIconContainer: {
    marginLeft: '3px',
    marginTop: '-2px'
  }
}));

export default function ButtonLanguagesSelector({ languages }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:680px)');

  const { buttonSettingsForm } = useSelector(state => state.devicesSlice);

  return (
    <div className='mb-16'>
      <Typography className={classes.infoLabel}>
        <span className='flex'>
          <EnhancedTranslate value='Select the language to provide data' />:
          <Tooltip title={<EnhancedTranslate value='button-languages-selector-tooltip-info' />}>
            <span className={classes.helpIconContainer}>
              <HelpIcon />
            </span>
          </Tooltip>
        </span>
      </Typography>
      <ButtonGroup className={classes.buttonGroup} size='large' orientation={matches ? 'horizontal' : 'vertical'}>
        {languages.map((language, idx) => (
          <Button
            key={language}
            className={clsx(
              {
                [classes.selected]: language === buttonSettingsForm.selectedLanguage
              },
              classes.button
            )}
            onClick={() => {
              dispatch(
                setInButtonSettingsForm({
                  key: 'selectedLanguage',
                  value: language
                })
              );
            }}
            startIcon={<Flag lang={language} size='3rem' rounded />}
            endIcon={
              idx === 0 ? (
                <Tooltip title={<EnhancedTranslate value='Main language' />}>
                  <HomeIcon color='secondary' />
                </Tooltip>
              ) : null
            }
          >
            <Typography className={classes.buttonLabel}>
              <EnhancedTranslate value={getLangName(language)} />
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
