import * as Actions from 'app/main/apps/communication/predefinedMessages/store/actions';

import _ from '../../../../../../../@lodash';
import { RESET_STORE } from '../../../../../../store/actions/reduxActions';

const initialState = {
  searchText: '',
  entities: [],
  selectedContactIds: [],
  routeParams: {},
  contactDialog: {
    type: 'new',
    props: {
      open: false
    },
    data: null
  },
  loading: false,
  translations: [],
  jobTitlesForPredefinedMessage: []
};

// eslint-disable-next-line complexity
const predefinedMessagesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true
      };
    }
    case Actions.GET_CONTACTS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
        routeParams: action.routeParams,
        loading: false
      };
    }
    case Actions.GET_JOB_TITLES_FOR_PREDEFINED_MESSAGE: {
      return {
        ...state,
        jobTitlesForPredefinedMessage: action.payload
      };
    }
    case Actions.GET_TRANSLATIONS_FOR_PREDEFINED_MESSAGE: {
      return {
        ...state,
        translations: action.payload
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.UPDATE_CONTACT: {
      let newEntities = { ...state.entities };

      newEntities[action.sms.id] = action.sms;

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.REMOVE_JOB_TITLE: {
      let newEntities = { ...state.entities };
      let jtId = parseInt(action.jobTitleId);
      let index = newEntities[action.predefinedMessageId]['jobTitlesIds'].indexOf(jtId);

      if (index !== -1) newEntities[action.predefinedMessageId]['jobTitlesIds'].splice(index, 1);

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.ADD_JOB_TITLE: {
      let newEntities = _.cloneDeep(state.entities);
      newEntities[action.predefinedMessageId]['jobTitlesIds'] = [
        ...newEntities[action.predefinedMessageId]['jobTitlesIds'],
        ...action.jobTitleId
      ];

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.UPDATE_TRANSLATION: {
      let translations = [...state.translations];

      const idx = translations.findIndex(x => x.translationId === action.translationId);

      translations[idx].translationText = action.newTranslationText;

      return {
        ...state,
        translations: translations
      };
    }
    case Actions.CHANGE_ORDER: {
      let newEntities = _.cloneDeep(state.entities);

      let sms1Id = Object.values(newEntities).find(x => x.order === action.order1).id;

      if (action.order1 < action.order2) {
        for (let entity in newEntities) {
          if (newEntities[entity].order > action.order1 && newEntities[entity].order <= action.order2) {
            newEntities[entity]['order'] = newEntities[entity]['order'] - 1;
          }
        }
      }

      if (action.order1 > action.order2) {
        for (let entity in newEntities) {
          if (newEntities[entity].order < action.order1 && newEntities[entity].order >= action.order2) {
            newEntities[entity]['order'] = newEntities[entity]['order'] + 1;
          }
        }
      }

      newEntities[sms1Id]['order'] = action.order2;

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.TOGGLE_IN_SELECTED_CONTACTS: {
      const contactId = action.contactId;

      let selectedContactIds = [...state.selectedContactIds];

      if (selectedContactIds.find(id => id === contactId) !== undefined) {
        selectedContactIds = selectedContactIds.filter(id => id !== contactId);
      } else {
        selectedContactIds = [...selectedContactIds, contactId];
      }

      return {
        ...state,
        selectedContactIds: selectedContactIds
      };
    }
    case Actions.TOGGLE_DISABLED: {
      let newEntities = { ...state.entities };
      newEntities[action.smsId]['active'] = action.state;
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.DISABLE_MULTIPLE: {
      let newEntities = { ...state.entities };

      for (let smsId of action.smsIds) {
        newEntities[smsId]['active'] = 0;
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.REMOVE_MESSAGES: {
      let newEntities = { ...state.entities };

      for (let smsId of action.messagesids) {
        delete newEntities[smsId];
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.ENABLE_MULTIPLE: {
      let newEntities = { ...state.entities };

      for (let smsId of action.smsIds) {
        newEntities[smsId]['active'] = 1;
      }
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.SELECT_ALL_CONTACTS: {
      const arr = Object.keys(state.entities).map(k => state.entities[k]);

      const selectedContactIds = arr.map(contact => contact.id);

      return {
        ...state,
        selectedContactIds: selectedContactIds
      };
    }
    case Actions.DESELECT_ALL_CONTACTS: {
      return {
        ...state,
        selectedContactIds: []
      };
    }
    case Actions.REMOVE_CONTACT: {
      let newEntities = { ...state.entities };
      delete newEntities[action.smsId];
      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.OPEN_NEW_CONTACT_DIALOG: {
      return {
        ...state,
        contactDialog: {
          type: 'new',
          props: {
            open: true
          },
          data: null
        }
      };
    }
    case Actions.CLOSE_NEW_CONTACT_DIALOG: {
      return {
        ...state,
        contactDialog: {
          type: 'new',
          props: {
            open: false
          },
          data: null
        }
      };
    }
    case Actions.OPEN_EDIT_CONTACT_DIALOG: {
      return {
        ...state,
        contactDialog: {
          type: 'edit',
          props: {
            open: true
          },
          data: action.data
        }
      };
    }
    case Actions.CLOSE_EDIT_CONTACT_DIALOG: {
      return {
        ...state,
        contactDialog: {
          type: 'edit',
          props: {
            open: false
          },
          data: null
        }
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default predefinedMessagesReducer;
