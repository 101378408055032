import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useHistory } from 'react-router';

import ActionIcon from './ActionIcon';

function ShowShadowActionIcon({ serial, isDisabled }) {
  const history = useHistory();

  const handleShowShadow = ev => {
    ev.stopPropagation();
    history.push(`/devices/shadow/${serial}`);
  };

  return <ActionIcon title='Show shadow' icon={<SearchIcon />} isDisabled={isDisabled} onClick={handleShowShadow} />;
}

export default ShowShadowActionIcon;
