export const getPageRowForSelection = table => {
  const pageRowSelection = [];

  if (table.length > 5) {
    pageRowSelection.push(5);
  }

  table &&
    table.forEach((item, index) => {
      if (!pageRowSelection.includes(table.length)) {
        pageRowSelection.push(table.length);
      }

      if (index !== 0 && index % 5 === 0 && index !== 5) {
        pageRowSelection.push(index);
      }
    });

  pageRowSelection.sort(function (a, b) {
    return a - b;
  });

  return pageRowSelection;
};
