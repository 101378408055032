import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import _ from '../../../../../@lodash';
import { LONG_ENDPOINT_TIMEOUT_60_SECONDS, UNRECOGNIZED_ERROR } from '../../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../../lib/getObid';
import { showMessage } from '../../../../store/actions';
import { RMA_VIEW_MANAGER_MODE } from '../constants';

export const initialState = {
  partnerOrders: null,
  managerOrders: null,
  rmaDetails: null,
  rmaStatusHistory: null,
  estimatedArrivalDate: null,
  estimatedArrivalDateLoading: false,
  estimatedArrivalDateError: null,
  estimatedArrivalDateErrorFields: null,
  placingOrderError: null,
  placingOrderLoading: false,
  isRmaPossible: null,
  rmaImpossibleReason: null,
  supportedCountriesListDialogOpen: false,
  supportedCountries: [],
  rmaCreateDialog: {
    open: false,
    mode: null
  },
  rmaDetailsDialog: {
    open: false,
    rmaId: null,
    mode: null
  },
  changingRmaStatusLoading: false,
  rmaCancelDialogId: null,
  rmaAuthorizationDialogId: null
};

export const fetchRmaOrdersForPartner = () => async dispatch => {
  axios.get('/api/rma').then(res => {
    dispatch(setPartnerOrders(res.data));
  });
};

export const fetchRmaOrdersForManager = () => async dispatch => {
  axios
    .get('/api/rma', {
      params: {
        'facility-id': getCurrentSelectedObid()
      }
    })
    .then(res => {
      dispatch(setManagerOrders(res.data));
    });
};

export const fetchSupportedCountries = () => async dispatch => {
  axios.get('/api/rma/supported-countries').then(res => {
    dispatch(setSupportedCountries(res.data));
  });
};

export const fetchEstimatedArrivalDate = (obid, googlePlaceId, products) => async dispatch => {
  dispatch(setEstimatedArrivalDateLoading(true));
  let postData = {
    obid,
    products,
    googlePlaceId
  };
  if (googlePlaceId === null) {
    delete postData.googlePlaceId;
  }
  axios
    .post('/api/rma/estimated-arrival', postData)
    .then(res => {
      dispatch(setEstimatedArrivalDate(res.data));
      dispatch(setEstimatedArrivalDateError(null));
    })
    .catch(err => {
      if (err.response && err.response.status === 400) {
        dispatch(setEstimatedArrivalDateError(err.response.data.code));
        dispatch(setEstimatedArrivalDateErrorFields(err.response.data.fields));
      } else {
        console.error(err);
        Sentry.captureException(err);
      }
    })
    .finally(() => {
      dispatch(setEstimatedArrivalDateLoading(false));
    });
};

export const placeShipmentOrder = (form, mode) => async dispatch => {
  dispatch(setPlacingOrderLoading(true));
  axios
    .post(
      '/api/rma',
      {
        ...form
      },
      {
        timeout: LONG_ENDPOINT_TIMEOUT_60_SECONDS
      }
    )
    .then(() => {
      dispatch(mode === RMA_VIEW_MANAGER_MODE ? fetchRmaOrdersForManager() : fetchRmaOrdersForPartner());
      dispatch(setPlacingOrderError(null));
    })
    .catch(err => {
      if (err.response && err.response.status === 400) {
        dispatch(setPlacingOrderError(err.response.data.code));
      } else {
        dispatch(setPlacingOrderError(UNRECOGNIZED_ERROR));
      }
    })
    .finally(() => {
      dispatch(setPlacingOrderLoading(false));
    });
};

export const fetchRmaDetails = rmaId => async dispatch => {
  axios.get(`/api/rma/${rmaId}`).then(res => {
    dispatch(setRmaDetails(res.data));
  });
};

export const fetchRmaStatusHistory = rmaId => async dispatch => {
  axios.get(`/api/rma/${rmaId}/status-history`).then(res => {
    dispatch(setRmaStatusHistory(res.data));
  });
};

export const cancelRma = rmaId => async dispatch => {
  dispatch(setChangingRmaStatusLoading(true));
  axios
    .post(`/api/rma/${rmaId}/cancel`)
    .then(res => {
      dispatch(
        setRmaStatus({
          rmaId: rmaId,
          changedBy: res.data.changedBy,
          date: res.data.date,
          newState: res.data.newState,
          subState: res.data.subState
        })
      );
      dispatch(
        showMessage({
          message: I18n.t('The order was canceled successfully'),
          autoHideDuration: 6000,
          variant: 'success'
        })
      );
    })
    .catch(() => {
      dispatch(
        showMessage({
          message: I18n.t('An error occurred while canceling the order'),
          autoHideDuration: 6000,
          variant: 'error'
        })
      );
    })
    .finally(() => {
      dispatch(setChangingRmaStatusLoading(false));
    });
};

export const authorizeRma = rmaId => async dispatch => {
  dispatch(setChangingRmaStatusLoading(true));
  axios
    .post(`/api/rma/${rmaId}/authorize`)
    .then(res => {
      dispatch(
        setRmaStatus({
          rmaId: rmaId,
          changedBy: res.data.changedBy,
          date: res.data.date,
          newState: res.data.newState,
          subState: res.data.subState
        })
      );
      dispatch(
        showMessage({
          message: I18n.t('The order was authorized successfully'),
          autoHideDuration: 6000,
          variant: 'success'
        })
      );
    })
    .catch(() => {
      dispatch(
        showMessage({
          message: I18n.t('An error occurred while authorizing the order'),
          autoHideDuration: 6000,
          variant: 'error'
        })
      );
    })
    .finally(() => {
      dispatch(setChangingRmaStatusLoading(false));
    });
};

export const fetchIsRmaPossible = (obid, products) => async dispatch => {
  axios
    .post(`/api/objects/${obid}/is-rma-possible`, {
      products,
      obid
    })
    .then(() => {
      dispatch(setIsRmaPossible(true));
      dispatch(setRmaImpossibleReason(null));
    })
    .catch(err => {
      dispatch(setIsRmaPossible(false));
      dispatch(setRmaImpossibleReason(err.response.data.code));
    });
};

const rmaSlice = createSlice({
  name: 'rma',
  initialState,
  reducers: {
    setRmaImpossibleReason: (state, action) => {
      state.rmaImpossibleReason = action.payload;
    },
    setIsRmaPossible: (state, action) => {
      state.isRmaPossible = action.payload;
    },
    setPartnerOrders: (state, action) => {
      state.partnerOrders = _.keyBy(action.payload, 'id');
    },
    setManagerOrders: (state, action) => {
      state.managerOrders = _.keyBy(action.payload, 'id');
    },

    setRmaDetails: (state, action) => {
      state.rmaDetails = action.payload;
    },
    setRmaStatusHistory: (state, action) => {
      state.rmaStatusHistory = action.payload;
    },
    setEstimatedArrivalDate: (state, action) => {
      state.estimatedArrivalDate = action.payload;
    },
    setEstimatedArrivalDateLoading: (state, action) => {
      state.estimatedArrivalDateLoading = action.payload;
    },
    setEstimatedArrivalDateError: (state, action) => {
      state.estimatedArrivalDateError = action.payload;
    },
    setEstimatedArrivalDateErrorFields: (state, action) => {
      state.estimatedArrivalDateErrorFields = action.payload;
    },

    openRmaCreateDialog: (state, action) => {
      state.rmaCreateDialog = {
        open: true,
        mode: action.payload
      };
    },
    openRmaDetailsDialog: (state, action) => {
      state.rmaDetailsDialog = {
        mode: action.payload.mode,
        rmaId: action.payload.rmaId
      };
    },
    closeRmaCreateDialog: state => {
      state.rmaCreateDialog = initialState.rmaCreateDialog;
      state.isRmaPossible = null;
      state.rmaImpossibleReason = null;
    },
    closeRmaDetailsDialog: state => {
      state.rmaDetailsDialog = initialState.rmaDetailsDialog;
      state.rmaDetails = initialState.rmaDetails;
    },
    setPlacingOrderError: (state, action) => {
      state.placingOrderError = action.payload;
    },
    setPlacingOrderLoading: (state, action) => {
      state.placingOrderLoading = action.payload;
    },
    setSupportedCountriesListDialogOpen: (state, action) => {
      state.supportedCountriesListDialogOpen = action.payload;
    },
    setSupportedCountries: (state, action) => {
      state.supportedCountries = action.payload;
    },
    openRmaCancelDialog: (state, action) => {
      state.rmaCancelDialogId = action.payload;
    },
    closeRmaCancelDialog: state => {
      state.rmaCancelDialogId = null;
    },
    openRmaAuthorizationDialog: (state, action) => {
      state.rmaAuthorizationDialogId = action.payload;
    },
    closeRmaAuthorizationDialog: state => {
      state.rmaAuthorizationDialogId = null;
    },
    setChangingRmaStatusLoading: (state, action) => {
      state.changingRmaStatusLoading = action.payload;
    },
    setRmaStatus: (state, action) => {
      const { rmaId, newState, subState, changedBy, date } = action.payload;

      if (state.partnerOrders && rmaId in state.partnerOrders) state.partnerOrders[rmaId].status = newState;

      if (state.managerOrders && rmaId in state.managerOrders) state.managerOrders[rmaId].status = newState;

      state.rmaDetails.status = newState;
      state.rmaStatusHistory = [
        ...state.rmaStatusHistory,
        {
          newState: newState,
          subState: subState,
          changedBy: changedBy,
          date: date
        }
      ];
    }
  }
});

export const {
  setPartnerOrders,
  setManagerOrders,
  setRmaDetails,
  setRmaStatusHistory,
  setEstimatedArrivalDate,
  setEstimatedArrivalDateLoading,
  setEstimatedArrivalDateError,
  openRmaCreateDialog,
  openRmaDetailsDialog,
  closeRmaCreateDialog,
  closeRmaDetailsDialog,
  setPlacingOrderError,
  setPlacingOrderLoading,
  openRmaCancelDialog,
  closeRmaCancelDialog,
  openRmaAuthorizationDialog,
  closeRmaAuthorizationDialog,
  setEstimatedArrivalDateErrorFields,
  setRmaImpossibleReason,
  setIsRmaPossible,
  setSupportedCountriesListDialogOpen,
  setSupportedCountries,
  setRmaStatus,
  setChangingRmaStatusLoading
} = rmaSlice.actions;
export default rmaSlice.reducer;
