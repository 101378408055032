import { useRoles } from 'app/custom-hooks/useRoles';
import { useSetting } from 'app/custom-hooks/useSetting';
import { getDeviceType } from 'app/shared/functions/device-type';
import { isOnline } from 'lib/commonFunctions';
import { SETTING_PRIVACY_RESTRICTIONS, SETTING_TYPE_BOOLEAN } from 'lib/constants';
import React from 'react';

import ButtonActions from './devices/ButtonActions';
import RvmActions from './devices/RvmActions';
import TabletActions from './devices/TabletActions';
import UnrecognizedActions from './devices/UnrecognizedActions';
import WatchActions from './devices/WatchActions';
import WebChatActions from './devices/WebChatActions';

const deviceActionComponents = {
  watch: WatchActions,
  webchat: WebChatActions,
  tablet: TabletActions,
  button: ButtonActions,
  reverse_vending_machine: RvmActions,
  unrecognized: UnrecognizedActions
};

function DeviceTableActions({ device }) {
  const [privacyRestrictions] = useSetting(SETTING_PRIVACY_RESTRICTIONS, SETTING_TYPE_BOOLEAN);

  const { hasPermissionToCreateEditRemove, isPartner, isService } = useRoles();

  const deviceType = getDeviceType(device);
  const isOnlineDevice = isOnline(device);

  const ActionComponent = deviceActionComponents[deviceType];

  if (!ActionComponent) return null;

  return (
    <div className='flex items-center'>
      <ActionComponent
        device={device}
        isDisabled={!hasPermissionToCreateEditRemove}
        isService={isService}
        isPartner={isPartner}
        isOnlineDevice={isOnlineDevice}
        hasPrivacyRestrictions={privacyRestrictions}
      />
    </div>
  );
}

export default DeviceTableActions;
