import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { STATUS_FAILED, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCEEDED } from 'lib/constants';

const initialState = {
  status: STATUS_INITIAL,
  data: {
    name: '',
    phone: '',
    picturePath: ''
  }
};

export const fetchBusinessCardData = createAsyncThunk('businessCard/fetch', async () => {
  const { data } = await axios.get(`/api/partners/dashboard-contact`);

  const modifiedData = {
    ...data,
    picturePath: data.picture_path
  };

  delete modifiedData.picture_path;

  return modifiedData;
});

const businessCardSlice = createSlice({
  name: 'businessCard',
  initialState,
  reducers: {
    editBusinessCard: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBusinessCardData.pending, state => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchBusinessCardData.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchBusinessCardData.rejected, state => {
        state.status = STATUS_FAILED;
        state.data = initialState.data;
      });
  }
});

export const { editBusinessCard } = businessCardSlice.actions;

export default businessCardSlice.reducer;
