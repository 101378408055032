import { Alert } from '@material-ui/lab';
import React, { useMemo } from 'react';

import {
  BUTTON_LABEL_KEY_FOUND_CENTER,
  BUTTON_LABEL_KEY_FOUND_HEADER,
  BUTTON_LABEL_KEY_LOOKING_FOR_CENTER,
  BUTTON_LABEL_KEY_LOOKING_FOR_HEADER,
  BUTTON_LABEL_KEY_MAIN_CENTER,
  BUTTON_LABEL_KEY_MAIN_FOOTER,
  BUTTON_LABEL_KEY_MAIN_HEADER,
  BUTTON_LABEL_KEY_NO_CONNECTION_CENTER,
  BUTTON_LABEL_KEY_NO_CONNECTION_HEADER,
  BUTTON_LABEL_KEY_NO_EMPLOYEES_CENTER,
  BUTTON_LABEL_KEY_NO_EMPLOYEES_HEADER,
  SETTINGS_HIDE_EMPLOYEES_NAMES,
  SETTING_BUTTON_LANGUAGES,
  SETTING_TYPE_BOOLEAN,
  SETTING_TYPE_STRING
} from '../../../../../lib/constants';
import EnhancedTranslate from '../../../../common-components/EnhancedTranslate';
import { useSetting } from '../../../../custom-hooks/useSetting';
import ChipInput, { CHIP_TYPE_NAME, CHIP_TYPE_NAMES, CHIP_TYPE_NUMBER } from '../ChipInput';
import DividerWithText from '../DividerWithText';
import ButtonLabelTextField from './ButtonLabelTextField';
import ButtonLanguagesSelector from './Dialogs/ButtonLanguagesSelector';

function ButtonLabelsSettingsForm() {
  const [hideEmployeesNames] = useSetting(SETTINGS_HIDE_EMPLOYEES_NAMES, SETTING_TYPE_BOOLEAN, false);
  const [buttonLanguages] = useSetting(SETTING_BUTTON_LANGUAGES, SETTING_TYPE_STRING, '');

  const languages = useMemo(() => {
    if (buttonLanguages) return buttonLanguages.split(',');
    else return [];
  }, [buttonLanguages]);

  return (
    <div>
      {languages.length > 1 && <ButtonLanguagesSelector languages={languages} />}
      <DividerWithText text='MAIN SCREEN' noMargin />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_MAIN_HEADER} name='Headline' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_MAIN_CENTER} name='Main text' />
      <ChipInput textId={BUTTON_LABEL_KEY_MAIN_FOOTER} chips={[CHIP_TYPE_NAMES, CHIP_TYPE_NUMBER]} name='Footer' />

      {hideEmployeesNames && (
        <Alert
          severity='warning'
          style={{
            marginTop: 16
          }}
        >
          <EnhancedTranslate value='hideEmployeesNamesButtonInfo' />
        </Alert>
      )}

      <DividerWithText text='SCREEN AFTER REQUEST' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_LOOKING_FOR_HEADER} name='Headline' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_LOOKING_FOR_CENTER} name='Main text' />
      <DividerWithText text='SCREEN AFTER ACCEPTING THE REQUEST' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_FOUND_HEADER} name='Headline' />
      <ChipInput textId={BUTTON_LABEL_KEY_FOUND_CENTER} chips={[CHIP_TYPE_NAME]} name='Main text' />
      <DividerWithText text='SCREEN WITH NO LINKED EMPLOYEES' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_NO_EMPLOYEES_HEADER} name='Headline' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_NO_EMPLOYEES_CENTER} name='Main text' />
      <DividerWithText text='SCREEN WITH NO CONNECTION / ERROR PAGE' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_NO_CONNECTION_HEADER} name='Headline' />
      <ButtonLabelTextField textId={BUTTON_LABEL_KEY_NO_CONNECTION_CENTER} name='Main text' />
    </div>
  );
}

export default React.memo(ButtonLabelsSettingsForm);
