import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

const initialState = {
  searchText: '',
  routeParams: {},
  permissions: [],
  entities: [],
  jobTitlesLoading: true,
  entityDialog: {
    type: 'new',
    props: {
      open: false
    },
    data: null
  },
  loading: false,
  data: [],
  jobTitleNameCollision: false
};

// eslint-disable-next-line complexity
const jobtitleReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.ADD_ENTITY: {
      return {
        ...state,
        entities: [...state.entities, { ...action.newEntity }]
      };
    }
    case Actions.UPDATE_ENTITY: {
      let newEntities = [...state.entities];
      let foundEntityIdx = newEntities.findIndex(entities => entities.id === action.payload.id);

      newEntities[foundEntityIdx] = {
        ...newEntities[foundEntityIdx],
        ...action.payload
      };

      return {
        ...state,
        entities: newEntities
      };
    }
    case Actions.REMOVE_JOB_TITLES: {
      for (let i = 0; i < action.payload.length; i++) {
        state.entities = state.entities.filter(x => x.id !== action.payload[i]);
      }

      return {
        ...state
      };
    }
    case Actions.OPEN_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: true
          },
          data: null
        }
      };
    }
    case Actions.CLOSE_NEW_ENTITY_DIALOG: {
      return {
        ...state,
        entityDialog: {
          type: 'new',
          props: {
            open: false
          },
          data: null
        }
      };
    }
    case Actions.GET_JOB_TITLES_DATA: {
      return {
        ...state,
        entities: [...action.payload]
      };
    }
    case Actions.SET_JOB_TITLES_LOADING: {
      return {
        ...state,
        jobTitlesLoading: action.payload
      };
    }
    case Actions.JOBTITLE_NAME_COLLISION: {
      return {
        ...state,
        jobTitleNameCollision: true
      };
    }
    case Actions.RESET_JOBTITLE_NAME_COLLISION: {
      return {
        ...state,
        jobTitleNameCollision: false
      };
    }
    case RESET_STORE:
      return initialState;
    default: {
      return state;
    }
  }
};

export default jobtitleReducer;
