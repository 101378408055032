import React from 'react';

import { ROLE_OWNER, ROLE_SERVICE, ROLE_VIEW_ONLY } from '../../../../lib/constants';

export const DashboardAccessAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  roleAuth: [ROLE_SERVICE, ROLE_OWNER, ROLE_VIEW_ONLY],
  routes: [
    {
      path: '/access',
      component: React.lazy(() => import('app/main/apps/dashboardAccess/DashboardAccessApp.js'))
    }
  ]
};
