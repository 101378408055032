export const COLOR_BLUE_100 = '#039BE5';

export const COLOR_CHARCOAL_5 = '#F8F9FC';
export const COLOR_CHARCOAL_10 = '#E4E9F5';
export const COLOR_CHARCOAL_20 = '#D3DDF5';
export const COLOR_CHARCOAL_30 = '#BEC8E1';
export const COLOR_CHARCOAL_40 = '#A9B3CC';
export const COLOR_CHARCOAL_60 = '#828BA4';
export const COLOR_CHARCOAL_80 = '#5E667B';
export const COLOR_CHARCOAL_100 = '#3C4252';
export const COLOR_CHARCOAL_150 = '#272A33';

export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BLACK = '#000000';

export const COLOR_GREY_5 = '#F8F8F8';
export const COLOR_GREY_20 = '#DCDCDC';
export const COLOR_GREY_40 = '#BABABA';
export const COLOR_GREY_60 = '#989898';
export const COLOR_GREY_80 = '#767575';
export const COLOR_GREY_100 = '#545151';

export const COLOR_WARNING_ORANGE_100 = '#F18F01';
export const COLOR_SUCCESS_GREEN_100 = '#157F1F';
export const COLOR_ERROR_RED_100 = '#EB0000';
