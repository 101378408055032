import { DEVICE_TYPE_TABLET } from 'app/shared/functions/device-type';
import React from 'react';

import DeleteActionIcon from '../icons/DeleteActionIcon';
import LocationActionIcon from '../icons/LocationActionIcon';
import LogsActionIcon from '../icons/LogsActionIcon';
import ResetActionIcon from '../icons/ResetActionIcon';
import ShowShadowActionIcon from '../icons/ShowShadowActionIcon';

function TabletActions({ device, isOnlineDevice, isDisabled, isPartner, isService, hasPrivacyRestrictions }) {
  return (
    <>
      {(isPartner || isService) && <DeleteActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isOnlineDevice && <ResetActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isService && <ShowShadowActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {!hasPrivacyRestrictions && (
        <>
          <LocationActionIcon device={device} />
          <LogsActionIcon serial={device.serial} deviceType={DEVICE_TYPE_TABLET} />
        </>
      )}
    </>
  );
}

export default TabletActions;
