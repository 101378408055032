import DateFnsUtils from '@date-io/date-fns';
import { AppBar, Button, CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransitionComponent } from '../../../../../../../lib/commonFunctions';
import EnhancedTranslate from '../../../../../../common-components/EnhancedTranslate';
import { closeDeviceLogsDialog, downloadDeviceLogs, fetchDeviceLogs } from '../../../store/DevicesSlice';

export default function DownloadLogsDialog() {
  const dispatch = useDispatch();

  const { open, serial, downloadLoading } = useSelector(state => state.devicesSlice.deviceLogsDialog);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (downloadLoading) {
      const intervalId = setInterval(() => {
        dispatch(fetchDeviceLogs(serial, moment(selectedDate).format('YYYYMMDD')));
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [downloadLoading, selectedDate]);

  function closeComposeDialog() {
    dispatch(closeDeviceLogsDialog());
  }

  function triggerDownloading() {
    dispatch(downloadDeviceLogs(serial, moment(selectedDate).format('YYYYMMDD')));
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  return (
    <Dialog open={open} TransitionComponent={TransitionComponent} onClose={closeComposeDialog}>
      <AppBar position='static' elevation={1}>
        <div className='p-24'>
          <div className='flex'>
            <Typography color='inherit' className='typography-h6 ml-8'>
              <EnhancedTranslate value='Download logs' />
            </Typography>
          </div>
        </div>
      </AppBar>
      <DialogContent className='p-24'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={downloadLoading}
            disableToolbar
            variant='inline'
            format='dd.MM.yyyy'
            margin='normal'
            label={<EnhancedTranslate value='Date' />}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>

        {downloadLoading && (
          <div className='mt-24 flex justify-center'>
            <CircularProgress />
          </div>
        )}

        <div className='flex justify-center'>
          <Button
            disabled={downloadLoading}
            className='mt-16'
            onClick={() => {
              triggerDownloading();
            }}
            variant='contained'
            color='secondary'
          >
            <EnhancedTranslate value='Download' />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
