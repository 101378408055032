import { combineReducers } from 'redux';

import archivedPings from './archivedPings.reducer';
import devices from './devices.reducer';

const reducer = combineReducers({
  devices,
  archivedPings
});

export default reducer;
