import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import { UNRECOGNIZED_ERROR } from '../../../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../../../lib/getObid';
import { showMessage } from '../../../../../store/actions';
import { ERROR_USER_ALREADY_HAS_PERMISSION, ERROR_USER_ALREADY_INVITED } from '../../constants';
import * as Actions from './index';

export const GET_USERS = '[ACCESS] GET USERS';
export const GET_INVITATIONS = '[ACCESS] GET INVITATIONS';
export const SELECT_ALL_ENTITIES = '[ACCESS] SELECT ALL ENTITIES';
export const DESELECT_ALL_ENTITIES = '[ACCESS] DESELECT ALL ENTITIES';
export const TOGGLE_IN_SELECTED_ENTITIES = '[ACCESS] TOGGLE IN SELECTED ENTITIES';
export const OPEN_NEW_ENTITY_DIALOG = '[ACCESS] OPEN NEW ENTITY DIALOG';
export const SET_SEARCH_TEXT = '[ACCESS] SET SEARCH TEXT';
export const SET_LOADING_TRUE = '[ACCESS] SET LOADING_TRUE';
export const SET_MANAGERS_INVITATIONS_LOADING_TRUE = '[ACCESS] SET MANAGERS INVITATIONS LOADING TRUE';
export const CLOSE_NEW_ENTITY_DIALOG = '[ACCESS] CLOSE NEW ENTITY DIALOG';
export const SET_LOADING_FALSE = '[ACCESS] SET LOADING FALSE';
export const SET_MANAGERS_INVITATIONS_LOADING_FALSE = '[ACCESS] SET MANAGERS INVITATIONS LOADING FALSE';

export function getEntities(loading = true, obid = null) {
  const request = axios.get('/api/dashboardUsers/list', {
    params: {
      selectedObid: obid ? obid : getCurrentSelectedObid()
    }
  });

  return dispatch => {
    if (loading) dispatch(setLoadingTrue());
    request
      .then(response =>
        dispatch({
          type: GET_USERS,
          payload: response.data
        })
      )
      .finally(() => {
        dispatch(setLoadingFalse());
      });
  };
}

export function getManagersInvitations(loading = true) {
  const request = axios.get(`/api/facilities/${getCurrentSelectedObid()}/dashboard-users-invitations`);

  return dispatch => {
    if (loading) dispatch(setManagersInvitationsLoadingTrue());
    request
      .then(response =>
        dispatch({
          type: GET_INVITATIONS,
          payload: response.data
        })
      )
      .finally(() => {
        dispatch(setManagersInvitationsLoadingFalse());
      });
  };
}

export function addEntity(newEntity) {
  return dispatch => {
    axios
      .post('/api/dashboard-users', {
        ...newEntity,
        facilityId: getCurrentSelectedObid(),
        selectedObid: getCurrentSelectedObid()
      })
      .then(() => {
        dispatch(
          showMessage({
            message: I18n.t('Invitation sent'),
            variant: 'success',
            autoHideDuration: 3000
          })
        );
        dispatch(Actions.getManagersInvitations());
      })
      .catch(error => {
        if ([ERROR_USER_ALREADY_HAS_PERMISSION, ERROR_USER_ALREADY_INVITED].includes(error?.response?.data?.error))
          dispatch(
            showMessage({
              message: I18n.t(error.response.data.error),
              variant: 'warning',
              autoHideDuration: 3000
            })
          );
        else
          dispatch(
            showMessage({
              message: I18n.t(UNRECOGNIZED_ERROR),
              variant: 'error',
              autoHideDuration: 3000
            })
          );
      });
  };
}

export function setLoadingTrue() {
  return {
    type: SET_LOADING_TRUE
  };
}

export function setManagersInvitationsLoadingTrue() {
  return {
    type: SET_MANAGERS_INVITATIONS_LOADING_TRUE
  };
}

export function setLoadingFalse() {
  return {
    type: SET_LOADING_FALSE
  };
}

export function setManagersInvitationsLoadingFalse() {
  return {
    type: SET_MANAGERS_INVITATIONS_LOADING_FALSE
  };
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value
  };
}

export function toggleInSelectedEntities(entityId) {
  return {
    type: TOGGLE_IN_SELECTED_ENTITIES,
    entityId
  };
}

export function selectAllEntities() {
  return {
    type: SELECT_ALL_ENTITIES
  };
}

export function deSelectAllEntities() {
  return {
    type: DESELECT_ALL_ENTITIES
  };
}

export function openNewEntityDialog() {
  return {
    type: OPEN_NEW_ENTITY_DIALOG
  };
}

export function closeNewEntityDialog() {
  return {
    type: CLOSE_NEW_ENTITY_DIALOG
  };
}

export function removeEntities(entityIds) {
  return dispatch => {
    const request = axios.post('/api/dashboardUsers/remove', {
      selectedObid: getCurrentSelectedObid(),
      uids: entityIds
    });

    return request.then(() => {
      dispatch(Actions.getEntities());
      dispatch(Actions.getManagersInvitations());
    });
  };
}
