import DeleteIcon from '@material-ui/icons/Delete';
import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import React from 'react';
import { useDispatch } from 'react-redux';

import ActionIcon from './ActionIcon';

function DeleteActionIcon({ serial, isDisabled }) {
  const dispatch = useDispatch();

  const handleRemoveDevice = ev => {
    ev.stopPropagation();
    dispatch(Actions.setSelectedDeviceSerial(serial));
    dispatch(Actions.openRemoveDeviceFromObjectDialog());
  };

  return (
    <ActionIcon
      title='Remove device from object'
      icon={<DeleteIcon />}
      isDisabled={isDisabled}
      onClick={handleRemoveDevice}
    />
  );
}

export default DeleteActionIcon;
