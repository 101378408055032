import axios from 'axios';

import { getCurrentSelectedObid } from '../../../../../../lib/getObid';

export const GET_ENTITIES = '[PERSONNEL ONLINE] GET ENTITIES';
export const SET_SEARCH_TEXT = '[PERSONNEL ONLINE] SET SEARCH TEXT';
export const SET_API_ERROR = '[PERSONNEL ONLINE] SET API ERROR';

export function getEntities() {
  const request = axios.get('/api/users/usersOnlinelist', {
    params: {
      selectedObid: getCurrentSelectedObid()
    }
  });

  return dispatch => {
    request
      .then(response =>
        dispatch({
          type: GET_ENTITIES,
          payload: response.data
        })
      )
      .catch(() => {
        dispatch(setError());
      });
  };
}

export function getEntitiesQuiet() {
  const request = axios.get('/api/users/usersOnlinelist', {
    params: {
      selectedObid: getCurrentSelectedObid()
    }
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ENTITIES,
        payload: response.data
      })
    );
  };
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value
  };
}

export function setError() {
  return {
    type: SET_API_ERROR
  };
}
