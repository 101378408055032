export const SET_SETTINGS = '[FUSE SETTINGS] SET SETTINGS';
export const SET_DEFAULT_SETTINGS = '[FUSE SETTINGS] SET DEFAULT SETTINGS';
export const RESET_DEFAULT_SETTINGS = '[FUSE SETTINGS] RESET DEFAULT SETTINGS';

export function setSettings(value) {
  return {
    type: SET_SETTINGS,
    value
  };
}

export function setDefaultSettings(value) {
  if (value) {
    localStorage.setItem('folded', !value.layout.config.navbar.folded);
  }
  return {
    type: SET_DEFAULT_SETTINGS,
    value
  };
}

export function resetSettings(value) {
  return {
    type: RESET_DEFAULT_SETTINGS,
    value
  };
}
