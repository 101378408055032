import React from 'react';

export const PredefinedMessagesAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/predefinedMessages/jobTitles/:predefinedMessageId?',
      component: React.lazy(() => import('./JobTitles/JobTitles'))
    },
    {
      path: '/predefinedMessages',
      component: React.lazy(() => import('app/main/apps/communication/predefinedMessages/PredefinedMessagesApp'))
    }
  ]
};
