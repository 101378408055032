import { makeStyles } from '@material-ui/core';
import EnhancedTextField from 'app/common-components/EnhancedTextField';
import RvmIcon from 'app/common-components/Icons/RvmIcon';
import React from 'react';

const useStyles = makeStyles(() => ({
  icon: {
    color: '#767575',
    width: 32,
    height: 32
  }
}));

function RvmSettingsGeneralTab({ name, onChange }) {
  const classes = useStyles();

  return (
    <div className='pt-32 pr-16'>
      <div className='flex h-52 overflow-visible'>
        <div className='min-w-48 self-center'>
          <RvmIcon className={classes.icon} />
        </div>
        <EnhancedTextField
          label='Device name'
          name='name'
          variant='outlined'
          value={name}
          onChange={e => {
            onChange(e.target.value);
          }}
          fullWidth
        />
      </div>
    </div>
  );
}

export default RvmSettingsGeneralTab;
