import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { amber, blue, green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
  info: {
    backgroundColor: blue[600],
    color: '#FFFFFF'
  },
  warning: {
    backgroundColor: amber[600],
    color: '#FFFFFF'
  }
}));

function FuseMessage() {
  const dispatch = useDispatch();
  const state = useSelector(({ fuse }) => fuse.message.state);
  const options = useSelector(({ fuse }) => fuse.message.options);

  const classes = useStyles();

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={() => dispatch(Actions.hideMessage())}
      classes={{
        root: classes.root
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div'
        }
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={<div className='flex items-center'>{options.message}</div>}
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' onClick={() => dispatch(Actions.hideMessage())}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export default React.memo(FuseMessage);
