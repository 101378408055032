import axios from 'axios';

import { getCurrentSelectedObid } from '../../../../../../lib/getObid';

export const GET_DEVICES_HISTORY = '[DEVICES PING HISTORY] GET DEVICES HISTORY';
export const SET_DEVICES_HISTORY_LOADING_TRUE = '[DEVICES PING HISTORY] SET LOADING TRUE';
export const SET_DEVICES_HISTORY_LOADING_FALSE = '[DEVICES PING HISTORY] SET DEVICES HISTORY LOADING FALSE';
export const GET_DEVICES_PINGS = '[DEVICES PING HISTORY] GET ARCHIVED PINGS';
export const GET_DEVICES_PINGS_COUNT = '[DEVICES PING HISTORY] GET ARCHIVED PINGS COUNT';

export function getArchivedPings(serial, page, pageSize) {
  const request = axios.post('/api/devices/getArchivedPings', {
    selectedObid: getCurrentSelectedObid(),
    serial,
    page: page || 0,
    pageSize: pageSize || 50
  });

  return dispatch => {
    dispatch(setLoadingTrue());
    request
      .then(response =>
        dispatch({
          type: GET_DEVICES_PINGS,
          payload: response.data
        })
      )
      .finally(() => {
        dispatch(setLoadingFalse());
      });
  };
}

export function getArchivedPingsCount(serial) {
  const request = axios.post('/api/devices/getArchivedPingsCount', {
    selectedObid: getCurrentSelectedObid(),
    serial
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_DEVICES_PINGS_COUNT,
        payload: response.data
      })
    );
  };
}

export function setLoadingTrue() {
  return {
    type: SET_DEVICES_HISTORY_LOADING_TRUE
  };
}

export function setLoadingFalse() {
  return {
    type: SET_DEVICES_HISTORY_LOADING_FALSE
  };
}
