import React from 'react';

import EnhancedTranslate from '../common-components/EnhancedTranslate';

export default function PrivacyPolicyLink() {
  return (
    <div className='flex' style={{ justifyContent: 'flex-end' }}>
      <a href='https://c.watch/privacy-policy.pdf' target='_blank' rel='noopener noreferrer'>
        <EnhancedTranslate value='Privacy policy' />
      </a>
    </div>
  );
}
