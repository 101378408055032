import RefreshIcon from '@material-ui/icons/Refresh';
import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import React from 'react';
import { useDispatch } from 'react-redux';

import ActionIcon from './ActionIcon';

function ResetActionIcon({ serial, isDisabled, isDevice }) {
  const dispatch = useDispatch();

  const handleRestartDevice = ev => {
    ev.stopPropagation();
    dispatch(Actions.setSelectedDeviceSerial(serial));
    if (isDevice) dispatch(Actions.openRestartDeviceDialog());
    else dispatch(Actions.openRestartAppDialog());
  };

  return (
    <ActionIcon
      title={isDevice ? 'Restart device' : 'Restart app'}
      icon={<RefreshIcon />}
      isDisabled={isDisabled}
      onClick={handleRestartDevice}
    />
  );
}

export default ResetActionIcon;
