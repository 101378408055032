import { useForm } from '@fuse/hooks';
import { Dialog, makeStyles } from '@material-ui/core';
import * as Actions from 'app/main/apps/devices/store/actions';
import { showMessage } from 'app/store/actions';
import axios from 'axios';
import { STATUS_LOADING } from 'lib/constants';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsDialogActions from '../../../SettingsDialogActions';
import { closeRvmDialog, updateRvm } from '../../../store/rvmSlice';
import RvmSettingsDialogContent, { RVM_TAB_GENERAL } from './RvmSettingsDialogContent';
import RvmSettingsDialogHeader from './RvmSettingsDialogHeader';
import RvmSettingsTabs from './RvmSettingsTabs';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }
}));

function RvmSettingsDialog() {
  const dispatch = useDispatch();

  const { isSettingsDialogOpen, rvm, status } = useSelector(({ rvm }) => rvm);

  const { obid } = useSelector(({ common }) => common.common);

  const { form, setForm, setInForm, resetForm } = useForm({
    name: '',
    primary_job_title_ids: [],
    primary_user_ids: [],
    secondary_job_title_ids: [],
    secondary_user_ids: []
  });

  const [currentTab, setCurrentTab] = useState(RVM_TAB_GENERAL);
  const [isPending, setIsPending] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (isSettingsDialogOpen) {
      setCurrentTab(RVM_TAB_GENERAL);
      resetForm();
    }
  }, [isSettingsDialogOpen]);

  useEffect(() => {
    if (rvm && isSettingsDialogOpen) {
      setForm(rvm);
    }
  }, [rvm, isSettingsDialogOpen]);

  const isLoading = status === STATUS_LOADING;

  const onClose = () => {
    const isModalLocked = isLoading || isPending || form.external_id !== rvm.external_id;
    if (isModalLocked) return;

    dispatch(closeRvmDialog());
  };

  const onChangeTab = (_e, tabValue) => {
    setCurrentTab(tabValue);
  };

  const onSubmit = async () => {
    try {
      setIsPending(true);
      await axios.patch(`/api/facilities/${obid}/reverse-vending-machine/${form.id}`, form);

      dispatch(updateRvm(form));
      dispatch(Actions.updateRvmInDeviceList(form));

      dispatch(showMessage({ message: 'Settings saved', variant: 'success' }));
    } catch (error) {
      dispatch(showMessage({ message: 'rvm_settings_save_error_message', variant: 'error' }));
    } finally {
      setIsPending(false);
      onClose();
    }
  };

  return (
    <Dialog open={isSettingsDialogOpen} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <RvmSettingsDialogHeader name={rvm.name} serial={rvm.external_id} isLoading={isLoading} />
      <RvmSettingsTabs currentTab={currentTab} onChange={onChangeTab} isNextTabsDisabled={isLoading} />
      <RvmSettingsDialogContent currentTab={currentTab} isLoading={isLoading} formData={form} updateForm={setInForm} />
      {!isLoading && (
        <div className='p-24'>
          <SettingsDialogActions
            onSubmit={onSubmit}
            canBeSubmitted={
              !isEqual(rvm, form) && (form.primary_job_title_ids.length > 0 || form.primary_user_ids.length > 0)
            }
            closeDialog={onClose}
            isPending={isPending}
          />
        </div>
      )}
    </Dialog>
  );
}

export default RvmSettingsDialog;
