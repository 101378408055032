import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { STATUS_FAILED, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCEEDED } from 'lib/constants';

const initialState = {
  productsFetchingStatus: STATUS_INITIAL,
  products: []
};

export const fetchProducts = createAsyncThunk('products/fetch', async () => {
  const { data } = await axios.get('/api/products');
  return data;
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, state => {
        state.productsFetchingStatus = STATUS_LOADING;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsFetchingStatus = STATUS_SUCCEEDED;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, state => {
        state.productsFetchingStatus = STATUS_FAILED;
        state.products = initialState.products;
      });
  }
});

export default productsSlice.reducer;
