import BarChartIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import MailIcon from '@material-ui/icons/Mail';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TimerIcon from '@material-ui/icons/Timer';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WatchIcon from '@material-ui/icons/Watch';
import React from 'react';

import { ROLE_OWNER, ROLE_SERVICE, ROLE_VIEW_ONLY, SETTING_PRIVACY_RESTRICTIONS } from '../../lib/constants';

const navigationConfig = [
  {
    id: 'summary',
    title: 'Summary',
    type: 'item',
    icon: <DashboardIcon />,
    url: '/summary'
  },
  {
    id: 'employees',
    title: 'Employees',
    type: 'item',
    icon: <GroupIcon />,
    url: '/users'
  },
  {
    id: 'communication',
    title: 'Communication',
    type: 'collapse',
    icon: <RecordVoiceOverIcon />,
    children: [
      {
        id: 'predefinedMessages',
        title: 'Predefined messages',
        type: 'item',
        icon: <MailIcon />,
        url: '/predefinedMessages'
      },
      {
        id: 'channels',
        title: 'Channels',
        icon: <SupervisorAccountIcon />,
        type: 'item',
        url: '/channels'
      }
    ]
  },
  {
    id: 'new-reports',
    title: 'Reports',
    type: 'collapse',
    icon: <BarChartIcon />,
    children: [
      {
        id: 'communication-reports',
        title: 'Communication',
        type: 'item',
        icon: <MailIcon />,
        url: '/reports/communication'
      },
      {
        id: 'work-register-reports',
        title: 'Time Registration',
        type: 'item',
        facilityAuth: [
          {
            name: SETTING_PRIVACY_RESTRICTIONS,
            value: '0'
          }
        ],
        icon: <TimerIcon />,
        url: '/reports/work-register'
      },
      {
        id: 'cwatches-reports',
        title: 'cWatch devices',
        type: 'item',
        icon: <WatchIcon />,
        url: '/reports/cwatches'
      },
      {
        id: 'buttons-reports',
        title: 'Buttons',
        type: 'item',
        icon: <RadioButtonCheckedIcon />,
        url: '/reports/buttons'
      }
    ]
  },
  {
    id: 'devices',
    title: 'Devices',
    type: 'collapse',
    icon: <WatchIcon />,
    children: [
      {
        id: 'devicesList',
        title: 'Devices list',
        type: 'item',
        icon: <WatchIcon />,
        url: '/devices'
      },
      {
        id: 'rma',
        title: 'Replacement orders',
        type: 'item',
        icon: <BuildIcon />,
        url: '/rma'
      }
    ]
  },
  {
    id: 'access',
    title: 'Access',
    type: 'item',
    roleAuth: [ROLE_SERVICE, ROLE_OWNER, ROLE_VIEW_ONLY],
    icon: <VpnKeyIcon />,
    url: '/access'
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'item',
    icon: <SettingsIcon />,
    url: '/settings'
  }
];

export default navigationConfig;
