import * as Actions from 'app/main/apps/devices/store/actions/devices.actions.js';
import { DEVICE_TYPE_BUTTON } from 'lib/constants';
import React from 'react';
import { useDispatch } from 'react-redux';

import { isButtonNotConfigured } from '../../DevicesTable';
import DeleteActionIcon from '../icons/DeleteActionIcon';
import DownloadLogsActionIcon from '../icons/DownloadLogsActionIcon';
import LocationActionIcon from '../icons/LocationActionIcon';
import LogsActionIcon from '../icons/LogsActionIcon';
import ResetActionIcon from '../icons/ResetActionIcon';
import ScreenShootActionIcon from '../icons/ScreenShootActionIcon';
import SettingsActionIcon from '../icons/SettingsActionIcon';
import ShowShadowActionIcon from '../icons/ShowShadowActionIcon';
import SimulationActionIcon from '../icons/SimulationActionIcon';

function ButtonActions({ device, isOnlineDevice, isDisabled, isPartner, isService, hasPrivacyRestrictions }) {
  const dispatch = useDispatch();

  const isButtonConfigured = !isButtonNotConfigured(device);

  const openSettingsDialog = () => {
    dispatch(Actions.openButtonSettingsDialog(device.serial));
  };

  return (
    <>
      {(isPartner || isService) && <DeleteActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isService && isOnlineDevice && <DownloadLogsActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isOnlineDevice && <ResetActionIcon serial={device.serial} isDisabled={isDisabled} isDevice />}
      <ScreenShootActionIcon serial={device.serial} deviceType={DEVICE_TYPE_BUTTON} />
      {isService && <ShowShadowActionIcon serial={device.serial} isDisabled={isDisabled} />}
      {isService && <SimulationActionIcon serial={device.serial} isDisabled={isDisabled} />}
      <SettingsActionIcon isDisabled={isDisabled} isConfigured={isButtonConfigured} onClick={openSettingsDialog} />
      {!hasPrivacyRestrictions && (
        <>
          <LocationActionIcon device={device} />
          <LogsActionIcon serial={device.serial} deviceType={DEVICE_TYPE_BUTTON} />
        </>
      )}
    </>
  );
}

export default ButtonActions;
