import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import { showMessage } from 'app/store/actions';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import ActionIcon from './ActionIcon';

function MenuSimulationActionIcon({ actionList, endpoint, isDisabled }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runSimulation = async type => {
    try {
      await axios.post(endpoint, {
        type
      });
    } catch (error) {
      dispatch(
        showMessage({
          message: I18n.t('Something went wrong'),
          variant: 'error'
        })
      );
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <ActionIcon title='Run simulation' icon={<ArrowRightIcon />} isDisabled={isDisabled} onClick={handleClick} />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {actionList.map(({ name, type }) => (
          <MenuItem key={type} onClick={() => runSimulation(type)}>
            <EnhancedTranslate value={name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MenuSimulationActionIcon;
