import { Typography } from '@material-ui/core';
import React from 'react';

import EnhancedTranslate from '../../../common-components/EnhancedTranslate';

export default function DividerWithText({ text, noMargin }) {
  return (
    <div className={noMargin ? 'flex' : 'mt-32 flex'}>
      <div
        style={{
          width: 8,
          marginTop: 8,
          height: 1,
          borderTop: '1px solid #3C4252'
        }}
      />
      <div style={{ marginLeft: 4 }} className='typography-input-label'>
        <Typography>
          <EnhancedTranslate value={text} />
        </Typography>
      </div>
      <div
        style={{
          marginTop: 8,
          height: 1,
          borderTop: '1px solid #3C4252',
          flex: 'auto',
          marginLeft: 4
        }}
      />
    </div>
  );
}
