import { AppBar, Dialog, DialogActions, DialogContent, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Actions from 'app/main/apps/devices/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatTimestamp, getTimestampFromScreenshotFname } from '../../../../../../lib/commonFunctions';
import { getCurrentSelectedObid } from '../../../../../../lib/getObid';
import EnhancedTranslate from '../../../../../common-components/EnhancedTranslate';
import ScreenshotImage from '../ScreenshotImage';

function DeviceScreenshotDialog() {
  const dispatch = useDispatch();
  const deviceScreenShotDialogOpen = useSelector(({ devices }) => devices.devices.deviceScreenshotDialogOpen);
  const deviceImageLoading = useSelector(({ devices }) => devices.devices.deviceScreenshotImageLoading);
  const selectedDeviceSerial = useSelector(({ devices }) => devices.devices.selectedDeviceSerial);
  const latestAvailableScreenshot = useSelector(({ devices }) => devices.devices.latestAvailableScreenshot);

  function closeComposeDialog() {
    dispatch(Actions.closeDeviceScreenshotDialog());
  }

  function refreshScreenshot() {
    dispatch(Actions.clearLatestAvailableScreenshot());
    dispatch(Actions.getScreenshot(selectedDeviceSerial));
    dispatch(Actions.setScreenshotImageLoadingTrue());
  }

  const obid = getCurrentSelectedObid();

  return (
    <Dialog
      open={deviceScreenShotDialogOpen}
      classes={{
        paper: 'm-24'
      }}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth='xs'
    >
      <AppBar position='static' elevation={1}>
        <Toolbar className='flex w-full justify-center'>
          <Typography variant='subtitle1' color='inherit'>
            <EnhancedTranslate value='Screenshot' />
          </Typography>
          &nbsp;
          {deviceImageLoading && <CircularProgress color='secondary' />}
        </Toolbar>
      </AppBar>
      <DialogTitle id='alert-dialog-slide-title' style={{ textAlign: 'center' }}>
        {latestAvailableScreenshot && formatTimestamp(getTimestampFromScreenshotFname(latestAvailableScreenshot))}
      </DialogTitle>
      <DialogContent
        className='flex'
        classes={{ root: 'p-24' }}
        style={{
          minWidth: '444px',
          minHeight: '444px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div className='flex'>
          {latestAvailableScreenshot && (
            <ScreenshotImage
              src={`${process.env.REACT_APP_API_URL}/screenshots/${obid}/${selectedDeviceSerial}/${latestAvailableScreenshot}`}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className='justify-between pl-16'>
        <Button onClick={refreshScreenshot} color='primary'>
          <EnhancedTranslate value='Refresh' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeviceScreenshotDialog;
