import { Collapse, IconButton, ListItem, ListItemText } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { cloneElement, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import EnhancedTranslate from '../../../../app/common-components/EnhancedTranslate';
import FuseNavBadge from './../FuseNavBadge';
import FuseNavVerticalGroup from './FuseNavVerticalGroup';
import FuseNavVerticalItem from './FuseNavVerticalItem';
import FuseNavVerticalLink from './FuseNavVerticalLink';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&.open': {
      backgroundColor: 'rgba(0,0,0,.08)'
    }
  },
  item: {
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    whiteSpace: 'pre-line',
    color: theme.palette.text.primary,
    '&.square': {
      width: '100%',
      borderRadius: '0'
    }
  }
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true;
    }
  }

  return false;
}

function FuseNavVerticalCollapse(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));
  const { item, nestedLevel, active } = props;
  let paddingValue = 40 + nestedLevel * 16;
  const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';

  useEffect(() => {
    if (needsToBeOpened(props.location, props.item)) {
      setOpen(true);
    }
  }, [props.location, props.item]);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <ul className={clsx(classes.root, open && 'open')}>
      <ListItem button className={clsx(classes.item, listItemPadding, 'list-item', active)} onClick={handleClick}>
        {item.icon && (
          <>
            {cloneElement(item.icon, {
              className: 'text-16 flex-shrink-0 mr-16',
              color: 'action'
            })}
          </>
        )}
        <ListItemText
          className='list-item-text'
          primary={<EnhancedTranslate value={item.title} />}
          classes={{
            primary: 'text-14'
          }}
        />
        {item.badge && <FuseNavBadge className='mr-4' badge={item.badge} />}
        <IconButton disableRipple className='h-16 w-16 p-0'>
          {open ? (
            <ExpandLessIcon className='arrow-icon text-16' color='inherit' />
          ) : (
            <ExpandMoreIcon className='arrow-icon text-16' color='inherit' />
          )}
        </IconButton>
      </ListItem>

      {item.children && (
        <Collapse in={open} className='collapse-children'>
          {item.children.map(item => (
            <React.Fragment key={item.id}>
              {item.type === 'group' && (
                <FuseNavVerticalGroup item={item} nestedLevel={nestedLevel + 1} active={active} />
              )}

              {item.type === 'collapse' && (
                <NavVerticalCollapse item={item} nestedLevel={nestedLevel + 1} active={active} />
              )}

              {item.type === 'item' && (
                <FuseNavVerticalItem item={item} nestedLevel={nestedLevel + 1} active={active} />
              )}

              {item.type === 'link' && (
                <FuseNavVerticalLink item={item} nestedLevel={nestedLevel + 1} active={active} />
              )}
            </React.Fragment>
          ))}
        </Collapse>
      )}
    </ul>
  );
}

FuseNavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.object,
    children: PropTypes.array
  })
};
FuseNavVerticalCollapse.defaultProps = {};

const NavVerticalCollapse = withRouter(React.memo(FuseNavVerticalCollapse));

export default NavVerticalCollapse;
