import BugReportIcon from '@material-ui/icons/BugReport';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openDeviceLogsDialog } from '../../../../store/DevicesSlice';
import ActionIcon from './ActionIcon';

function DownloadLogsActionIcon({ serial, isDisabled }) {
  const dispatch = useDispatch();

  const handleDownloadLogs = ev => {
    ev.stopPropagation();
    dispatch(openDeviceLogsDialog(serial));
  };

  return (
    <ActionIcon title='Download logs' icon={<BugReportIcon />} isDisabled={isDisabled} onClick={handleDownloadLogs} />
  );
}

export default DownloadLogsActionIcon;
