import React from 'react';

export const USERS_ROUTE = '/users';
export const PersonnelAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: USERS_ROUTE,
      component: React.lazy(() => import('app/main/apps/personnel/PersonnelApp'))
    }
  ]
};
