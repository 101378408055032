import { useSelector } from 'react-redux';

function useAssigneesPanels(
  selectedJobTitleIds,
  selectedUserIds,
  disabledJobTitleIds,
  disabledUserIds,
  updateJobTitleIds,
  updateUserIds,
  updatedDisabledUserIds
) {
  const jobTitlesWithUsers = useSelector(({ forSelect }) => forSelect.forSelect.jobTitlesWithUsers);

  const getUserIdsForThisJobTitle = jobTitleId =>
    jobTitlesWithUsers.find(jobTitle => jobTitle.id === jobTitleId)?.users.map(user => user.id) || [];

  const getAllUsersForThisJobTitleWithoutSelectedOne = (jobTitleId, userId) =>
    getUserIdsForThisJobTitle(jobTitleId).filter(user => user !== userId);

  const handleJobTitleCheck = (jobTitleId, checked) => {
    if (disabledJobTitleIds.includes(jobTitleId)) return;

    const userIdsForThisJobTitle = getUserIdsForThisJobTitle(jobTitleId);

    if (checked) {
      const hasUserInOtherGroup = disabledUserIds.some(userId => userIdsForThisJobTitle.includes(userId));

      updateJobTitleIds([...selectedJobTitleIds, jobTitleId]);

      if (hasUserInOtherGroup) {
        updatedDisabledUserIds(disabledUserIds.filter(userId => !userIdsForThisJobTitle.includes(userId)));
      } else {
        updateUserIds(selectedUserIds.filter(userId => !userIdsForThisJobTitle.includes(userId)));
      }
    } else {
      updateJobTitleIds(selectedJobTitleIds.filter(id => id !== jobTitleId));
      updateUserIds(selectedUserIds.filter(id => !userIdsForThisJobTitle.includes(id)));
    }
  };

  const handleUserCheck = (userId, checked, jobTitleId) => {
    if (disabledUserIds.includes(userId)) return;

    if (checked) {
      if (selectedUserIds.includes(userId)) return;

      const allSelectedUsers = [...selectedUserIds, userId];
      const userIdsForThisJobTitle = getUserIdsForThisJobTitle(jobTitleId);
      const hasAllUsers = userIdsForThisJobTitle.every(id => allSelectedUsers.includes(id));

      if (hasAllUsers) {
        updateUserIds(selectedUserIds.filter(id => !userIdsForThisJobTitle.includes(id)));
        updateJobTitleIds([...selectedJobTitleIds, jobTitleId]);
      } else {
        updateUserIds(allSelectedUsers);
      }
    } else {
      if (selectedJobTitleIds.includes(jobTitleId)) {
        updateJobTitleIds(selectedJobTitleIds.filter(id => id !== jobTitleId));
        updateUserIds([...selectedUserIds, ...getAllUsersForThisJobTitleWithoutSelectedOne(jobTitleId, userId)]);
      } else {
        updateUserIds(selectedUserIds.filter(id => id !== userId));
      }
    }
  };

  const getUserIdsForManyJobTitle = jobTitleIds =>
    jobTitlesWithUsers
      .filter(jobTitle => jobTitleIds.includes(jobTitle.id))
      .flatMap(jobTitle => jobTitle.users.map(user => user.id));

  return {
    handleJobTitleCheck,
    handleUserCheck,
    getUserIdsForManyJobTitle,
    jobTitlesWithUsers
  };
}

export default useAssigneesPanels;
