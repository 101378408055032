import React from 'react';

export const ChannelsAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/channel/users/:channelId?',
      component: React.lazy(() => import('app/main/apps/channels/connectedUsers/ConnectedUsers'))
    },
    {
      path: '/channels',
      component: React.lazy(() => import('app/main/apps/channels/ChannelsApp'))
    }
  ]
};
