import { useRef } from 'react';

export default function useAutocompleteService() {
  const autocompleteService = useRef();

  if (!autocompleteService.current) {
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
  }
  return autocompleteService;
}
