import _ from 'lodash';

import {
  SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_PASSWORD,
  SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_USER,
  SETTING_PERSONNEL_SYNCHRONIZATION_URL
} from '../../../../../../lib/constants';
import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import * as Actions from '../actions';

export const GENERAL_SETTINGS = 'general';
export const BREAKS_SETTINGS = 'breaks';
export const WIFI_SETTINGS = 'wifi';
export const BUTTONS_SETTINGS = 'buttons';
export const BRIDGE_SETTINGS = 'bridge';
export const PERSONNEL_SYNC_SETTINGS = 'personnelSync';
export const TEMPORARY_USERS_SETTINGS = 'temporaryUsers';
export const TRANSLATIONS_SETTINGS = 'translations';

const initialState = {
  settings: {},
  wifi: [],
  wifiDialogOpen: false,
  editWifiData: null,
  wifiUsage: {},
  personnelSynchronizationHistory: [],
  error: false,
  csvFileFormatDialogOpen: false,
  personnelSynchronizationChangeDetailsDialogOpen: false,
  personnelSynchronizationChangeDetails: [],
  personnelSynchronizationLoading: false,
  tab: GENERAL_SETTINGS
};

// eslint-disable-next-line complexity
const settingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SETTINGS: {
      if (action.payload != null && action.payload.wifiList != null && action.payload.wifiList.length > 0) {
        let wifiJsoned = _.toArray(JSON.parse(action.payload.wifiList));
        let parsedArray = [];

        if (wifiJsoned != null) {
          wifiJsoned.forEach((element, index) => {
            parsedArray.push({
              name: Object.keys(JSON.parse(action.payload.wifiList))[index],
              password: element.password,
              security: element.security,
              hidden: element.hidden
            });
          });
        }
        return {
          ...state,
          settings: {
            ...action.payload,
            wifiList: parsedArray
          }
        };
      }
      return {
        ...state,
        settings: {
          ...action.payload,
          wifiList: []
        }
      };
    }
    case Actions.OPEN_WIFI_DIALOG: {
      return {
        ...state,
        wifiDialogOpen: true
      };
    }
    case Actions.SET_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }
    case Actions.SET_PERSONNEL_SYNCHRONIZATION_LOADING_TRUE: {
      return {
        ...state,
        personnelSynchronizationLoading: true
      };
    }
    case Actions.SET_PERSONNEL_SYNCHRONIZATION_LOADING_FALSE: {
      return {
        ...state,
        personnelSynchronizationLoading: false
      };
    }
    case Actions.SET_SETTING_VALUE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.name]: action.value
        }
      };
    }
    case Actions.SET_PERSONNEL_SYNCHRONIZATION_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [SETTING_PERSONNEL_SYNCHRONIZATION_URL]: action.url,
          [SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_USER]: action.user,
          [SETTING_PERSONNEL_SYNCHRONIZATION_AUTH_PASSWORD]: action.password
        }
      };
    }
    case Actions.SET_ERROR: {
      return {
        ...state,
        error: true
      };
    }
    case Actions.SET_EDIT_WIFI_DATA: {
      return {
        ...state,
        editWifiData: {
          name: action.wifi.name,
          password: action.wifi.password,
          security: action.wifi.security,
          hidden: action.wifi.hidden
        }
      };
    }
    case Actions.CLOSE_WIFI_DIALOG: {
      return {
        ...state,
        wifiDialogOpen: false,
        editWifiData: null
      };
    }
    case Actions.GET_PERSONNEL_SYNCHRONIZATION_HISTORY: {
      return {
        ...state,
        personnelSynchronizationHistory: action.payload
      };
    }
    case Actions.ADD_WIFI: {
      let newWifi = [...state.settings.wifiList];
      newWifi.push({
        name: action.ssid,
        password: action.password,
        security: action.security,
        hidden: action.hidden
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          wifiList: newWifi
        }
      };
    }
    case Actions.EDIT_WIFI: {
      let newWifi = [...state.settings.wifiList];
      let idx = newWifi.findIndex(x => x.name === action.ssid);

      newWifi[idx].name = action.ssid;
      newWifi[idx].password = action.password;
      newWifi[idx].security = action.security;
      newWifi[idx].hidden = action.hidden;

      return {
        ...state,
        settings: {
          ...state.settings,
          wifiList: newWifi
        }
      };
    }
    case Actions.REMOVE_WIFI: {
      let newWifi = [...state.settings.wifiList];
      let wifisAfterDelete = newWifi.filter(wifi => wifi.name !== action.payload);

      return {
        ...state,
        settings: {
          ...state.settings,
          wifiList: wifisAfterDelete
        }
      };
    }
    case Actions.GET_WIFI_USAGE: {
      return {
        ...state,
        wifiUsage: action.payload
      };
    }
    case Actions.GET_CONNECTION_RATIO: {
      return {
        ...state,
        connectionRatio: action.payload
      };
    }
    case Actions.OPEN_CSV_FILE_FORMAT_DIALOG: {
      return {
        ...state,
        csvFileFormatDialogOpen: true
      };
    }
    case Actions.CLOSE_CSV_FILE_FORMAT_DIALOG: {
      return {
        ...state,
        csvFileFormatDialogOpen: false
      };
    }
    case Actions.OPEN_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG: {
      return {
        ...state,
        personnelSynchronizationChangeDetailsDialogOpen: true,
        personnelSynchronizationChangeDetails: action.details
      };
    }
    case Actions.CLOSE_PERSONNEL_SYNCHRONIZATION_CHANGE_DETAILS_DIALOG: {
      return {
        ...state,
        personnelSynchronizationChangeDetailsDialogOpen: false,
        personnelSynchronizationChangeDetails: []
      };
    }
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default settingsReducer;
