import { NavLinkAdapter } from '@fuse';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FuseNavBadge from './../FuseNavBadge';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 48,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      '& .list-item-text-primary': {
        color: 'inherit'
      },
      '& .list-item-icon': {
        color: 'inherit'
      }
    },
    '& .list-item-icon': {},
    '& .list-item-text': {
      padding: '0 0 0 16px'
    },
    color: theme.palette.text.primary,
    textDecoration: 'none!important',
    '&.dense': {
      padding: '8px 12px 8px 12px',
      minHeight: 40,
      '& .list-item-text': {
        padding: '0 0 0 8px'
      }
    }
  }
}));

function FuseNavHorizontalItem(props) {
  const dispatch = useDispatch();

  const classes = useStyles(props);
  const { item, dense } = props;

  return (
    <ListItem
      button
      component={NavLinkAdapter}
      to={item.url}
      activeClassName='active'
      className={clsx('list-item', classes.root, dense && 'dense')}
      onClick={() => dispatch(Actions.navbarCloseMobile())}
      exact={item.exact}
    >
      {item.icon && (
        <>
          {cloneElement(item.icon, {
            className: 'list-item-icon text-16 flex-shrink-0',
            color: 'action'
          })}
        </>
      )}
      <ListItemText
        className='list-item-text'
        primary={item.title}
        classes={{
          primary: 'text-14 list-item-text-primary'
        }}
      />
      {item.badge && <FuseNavBadge className='ml-8' badge={item.badge} />}
    </ListItem>
  );
}

FuseNavHorizontalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string
  })
};

FuseNavHorizontalItem.defaultProps = {};

const NavHorizontalItem = withRouter(React.memo(FuseNavHorizontalItem));

export default NavHorizontalItem;
