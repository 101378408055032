import { ROLE_OWNER, ROLE_SERVICE } from '../../../../lib/constants';
import { RESET_STORE } from '../../../store/actions/reduxActions';
import * as Actions from '../actions';

const initialState = {
  packageId: null
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case Actions.SET_PACKAGE_ID: {
      return {
        ...state,
        packageId: action.packageId
      };
    }
    case Actions.GET_USER_ROLE: {
      let currentRole = null;

      if (state.serviceUser) currentRole = ROLE_SERVICE;
      else if (state.partner) currentRole = ROLE_OWNER;
      else if (action.roles) {
        let currentObjectRole = action.roles.find(x => String(x.obid) === String(action.obid));
        currentRole = currentObjectRole.role;
      }

      return {
        ...state,
        roles: action.roles,
        currentRole: currentRole
      };
    }
    case Actions.UPDATE_CURRENT_ROLE: {
      let currentRole = null;

      if (state.serviceUser) {
        currentRole = ROLE_SERVICE;
      } else if (state.partner) {
        currentRole = ROLE_OWNER;
      } else {
        let currentObjectRole = state.roles.find(x => x.obid === String(action.obid));
        currentRole = currentObjectRole.role;
      }

      return {
        ...state,
        currentRole: currentRole
      };
    }
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default user;
