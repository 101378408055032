import { fetchRvmSettings, openRvmSettingsDialog } from 'app/main/apps/devices/store/rvmSlice';
import { STATUS_LOADING, STATUS_SUCCEEDED } from 'lib/constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MenuSimulationActionIcon from '../icons/MenuSimulationActionIcon';
import SettingsActionIcon from '../icons/SettingsActionIcon';

const simulations = [
  {
    name: 'Overload',
    type: 'overload'
  },
  {
    name: 'Error',
    type: 'error'
  },
  {
    name: 'printer_out_of_paper',
    type: 'printer_out_of_paper'
  }
];

function RvmActions({ device, isDisabled, isService }) {
  const dispatch = useDispatch();
  const obid = useSelector(({ common }) => common.common.obid);
  const { status, rvm } = useSelector(({ rvm }) => rvm);

  const onDialogOpen = () => {
    dispatch(openRvmSettingsDialog());
    if (rvm.id !== device.id || ![STATUS_SUCCEEDED, STATUS_LOADING].includes(status)) {
      dispatch(
        fetchRvmSettings({
          facilityId: obid,
          rvmId: device.id
        })
      );
    }
  };

  return (
    <>
      {isService && (
        <MenuSimulationActionIcon
          actionList={simulations}
          endpoint={`/api/facilities/${obid}/reverse-vending-machine/${device.id}/simulate-event`}
          isDisabled={isDisabled}
        />
      )}
      <SettingsActionIcon isDisabled={isDisabled} isConfigured={device.is_configured} onClick={onDialogOpen} />
    </>
  );
}

export default RvmActions;
